import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {DateFunc} from '../../Common/Date';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Pagination from '../Layout/Pagination';
import * as QuizApi from '../../api/quiz';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { defaultPage } from '../../Common/Constant';

import { userList, userCityList } from '../../api/user';
import { get, getDownloadFile } from "../../services/api.services";



function Players() {
   
    const location = useLocation();
    const queryString = new URLSearchParams(location.search)
    const qid = queryString.get('id'); //console.log(id);  
    
    useEffect(() => {
        document.title = "Subscriber List - QIM redFM";
    },);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [city, setCity] = useState("");
    const [cities, setCities] = useState([]);
    //const [startDate, setStartDate] = useState();//useState(new Date());//datepicker start date
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const onChange = (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };
    const [lists, setLists] = useState();
    const [pageInfo, setPageInfo] = useState({});
    const [errors, setErrors] = useState({});    
    const [registrationData, setRegistrationData] = useState([]);
    const [quizList, setQuizList] = useState();
    const [quiz, setQuiz] = useState();
    const [quizID, setQuizID] = useState();
    const [questions, setQuestions] = useState([]);
    const [selectedQuestion, setSelcetedQuestion] = useState([]);
    const [questionID, setQuestionID] = useState(); 

    const getQuizList = async() =>{
        setErrors();
        let res = await QuizApi.getList({page:0, perPage: 500, searchKeyword: searchKeyword,status:[1,2,3,4,5]});
        if(res.status === 1){
            setQuizList(res.payload)
        } else{
            setQuizList();
            setErrors({...errors,['records']:"No record found"});
        }        
    }
    useEffect(() => {
        getQuizList();
    },[]);


    const getQuizQuestions = async() =>{
        setErrors();
        let res = await QuizApi.getQuestionSetList({id:quizID, page:0, perPage: 50});
        if(res.status === 1){
            setQuestions(res.payload); 
            res.payload.map((item,index)=>{
                item.no = index +1;
            })     
            //console.log(questions) 
            setSelcetedQuestion([])
            if(res.payload?.length == 1)
                setSelcetedQuestion(res.payload[0])
                //console.log(selectedQuestion,"test")
        } else{
            setQuestions([]);
            setErrors({...errors,['records']:"No record found"});
        }        
    }
    useEffect(() => {
        getQuizQuestions();
    },[quizID]);

       
    // useEffect(() => {
    //     getUserCities();
    // },[]);

    useEffect(() => {
        getList();
    },[selectedQuestion]);

    const getList = async() =>{
        if((quizID && quizID != null) || (selectedQuestion && selectedQuestion?.qqID)){
            let startDate1 = "";
            let endDate1 = "";
            if(startDate != "" && startDate != null){
                //const date = new Date();
                 startDate1 = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
            } 
            if(endDate != "" && endDate != null){
                //const date = new Date();
                 endDate1 = `${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}`;
            } 
            //let res = await userList({page: pageInfo.current_page, perPage: defaultPage.perPage, q: searchKeyword, city,startDate, endDate});
            let res = await get('/user/player/list', {quizID,qqID:selectedQuestion?.qqID , page: pageInfo.current_page, perPage: defaultPage.perPage,q:searchKeyword,city,startDate: startDate1, endDate:endDate1})
            if(res.status === 1){
                setLists(res.payload)
                setPageInfo(res.paginationInfo)
            } else{
                setLists([])
            }
        }
    }
   
    const getUserCities = async() =>{
        let res = await userCityList();
        if(res.status === 1){
            setCities(res.payload)
        } else{
            setCities([])
        }
    }

    const changePage = (p) => {
        pageInfo.current_page = p;
        getList();
            }         


    const applyFilter=async(e)=>{
        e.preventDefault();
        getList();
        }

    const clearFilter=()=>{
        //document.getElementById('registrationDate').value="";
        document.getElementById('searchKeyword').value="";
        //document.getElementById('city').value="";
        setSearchKeyword("");
        setCity("");
        setStartDate("");
        setEndDate("");
        //getList();
    }

   
    const setQuiz1=(ID)=>{
        setQuizID();
        setQuiz();
        setQuestionID();
        setSelcetedQuestion([])
        if(ID && ID != "" && quizList?.length > 0){
            setQuizID(ID)
            for( let i = 0; i < quizList?.length; i++){
                if(quizList[i].ID == ID){
                    setQuiz(quizList[i]);
                    break;
                }
            }
        }
    }

    const setQuestion=(ID)=>{
        setQuestionID();
        setSelcetedQuestion([])
        if(ID && ID != "" && questions?.length > 0){
            setQuestionID(ID)
            for( let i = 0; i < questions?.length; i++){
                if(questions[i].ID == ID){
                    setSelcetedQuestion(questions[i]);
                    break;
                }
            }
        }
    }

    const getDayWiseRegistrationData = async() => {    
        let res = await get('/dashboard/daywise-registration', {days:DateFunc.last7Days()});
        setRegistrationData(res.payload)
    }
    // useEffect(() => {
    //     getDayWiseRegistrationData();
    // },[]);
    let chartInfo = {
        labels: DateFunc.last7Days('MMMddYYYY'),
        data: registrationData,
        showTitle:false
    }

    const downloadUserData = async () => {
        let startDate1 = "";
        let endDate1 = "";
        if(startDate != "" && startDate != null){
            //const date = new Date();
             startDate1 = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
        } 
        if(endDate != "" && endDate != null){
            //const date = new Date();
             endDate1 = `${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}`;
        } 
        let now =  DateFunc.format(new Date().getTime());
                                                                       
        await getDownloadFile("/user/player/list/download?quizID="+quizID+"&qqID="+selectedQuestion?.qqID+"&page="+pageInfo.current_page+"&perPage="+defaultPage.perPage+"&city="+city+"&q="+searchKeyword+"&startDate="+startDate1+"&endDate="+endDate1, `Players - ${quiz?.title}`);
    };


    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Players & Winners</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item">Quiz</li>
                                                <li className="breadcrumb-item active">Players</li>
                                            </ol>
                                        </div>
                                            
                                        </div> 
                                </div>
                            </div>


                            {/* <!-- end page title --> */}
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card" style={{marginBottom:"10px"}}>
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <div className='row' style={{borderBottom:"1px solid #bbb", marginBottom:"10px"}}>
                                                <div className='col-md-4'  >
                                                    <h4 >QUIZ &nbsp; &nbsp; 
                                                    <select  id="city" onChange={(e)=>setQuiz1(e.target.value)} style={{display:'inline-block',padding:"0px", width:"90%", fontSize:"14px"}} >
                                                        <option value={""}>Select Quiz</option>
                                                        {
                                                            quizList && quizList.map(q =>{
                                                                return <option value={q.ID} 
                                                                //selected ={(qid && qid==q.ID)?true:false}
                                                                >{q.title}</option>
                                                            })
                                            }

                                                    </select>
                                                    </h4>
                                                </div>
                                                <div className='col-md-8'  >
                                                    <h4 >QUESTIONS &nbsp; &nbsp; 
                                                    <select  id="city" onChange={(e)=>setQuestion(e.target.value)} style={{display:'inline-block',padding:"0px", width:"100%", fontSize:"14px"}}>
                                                        <option value={""}>Select Questions</option>
                                                        {
                                                            questions && questions.map(q =>{
                                                                return <option value={q.ID} dangerouslySetInnerHTML={{__html:q.no+") "+q.question}}></option>
                                                            })
                                            }

                                                    </select>
                                                    </h4>
                                        </div>
                                    </div>
                                            <div className='row'>
                                                {quiz && (
                                                    <>
                                                        <div className='col-md-4'>

                                                            <h4 style={{textAlign:"left"}}>{quiz?.title}</h4>
                                                            {/* <div style={{textAlign:"left","font-size":"12px"}}>
                                                                <b>Sub Title:</b> {quiz?.subTitle}
                                </div>
                                                            <div style={{textAlign:"left","font-size":"12px"}}>
                                                                <b>Desc:</b> {quiz?.description}
                                                            </div>   */}
                                                            <div>
                                                            <b>Type:</b> <span style={{textAlign:"left"}}>{quiz?.type == 1?"Live":(quiz?.type == 0?"Practice":"Regular (Powerplay)")}</span>
                            </div>
                            
                                                            <div style={{textAlign:"left","font-size":"12px"}}>
                                                                <b>Conducted On:</b> {quiz?.conductedDate}
                                        </div>

                                                            <button  onClick={(()=>window.open("/quiz/winners?id="+quizID+"&quizName="+quiz.title+"&pageTitle=Winner List", "_blank", "noreferrer"))} target='_blank' className="btn btn-sm btn-danger"  style={{'font-size':"14px",marginTop:"5px"}}>
                                                                        <i className="mdi mdi-trophy" ></i> Manage/View Winners
                                                                    </button>
                                        </div>
                                                        <div className='col-md-8'>
                                                            {
                                                                selectedQuestion && selectedQuestion.question && (
                                                                    <>
                                                                    <b> <span  dangerouslySetInnerHTML={{__html:"Q "+selectedQuestion.no+". "+selectedQuestion.question}}></span></b>
                                                                    <div>
                                                                        {selectedQuestion.options?.length > 0 ? (
                                                                            <>
                                                                            {selectedQuestion.options.map( (item, index) => {
                                                                                return(<span style={{color:(item.option == selectedQuestion.correctOption)?"green":"",fontWeight:(item.option == selectedQuestion.correctOption)?"bold":""}}>{String.fromCharCode(index++ + 97)}) {item.option} &nbsp;  &nbsp;  &nbsp; &nbsp; </span>)
                                                                            })
                                                                            }
                                                                            </>
                                                                        ):(<></>)
                                                                        }
                                                                        {(selectedQuestion.explanation!="" && selectedQuestion.explanation != null) &&(<>
                                                                            <br/><br/>
                                                                            <span style={{fontSize:"14px",fontStyle:'italic'}}>Explantion: {selectedQuestion.explanation}</span>
                                                                        </>
                                                                           
                                                                        )
                                                                        }
                                                                    </div>                                                                    
                                                                    </>
                                                                        )
                                                                    }
                                                               
                                                        </div>

                                                                        </>
                                                                             )}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card" style={{marginBottom:"10px"}}>
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <form className='row' onSubmit={applyFilter}>
                                                <div className='col-md-3'>
                                                    Search <br/>
                                                    <input className='' 
                                                    style={{width:"100%", display:"inline-block"}}
                                                    placeholder='by name, mobile, email' 
                                                    id="searchKeyword"  
                                                    onKeyUp={(e)=>setSearchKeyword(e.target.value)}
                                                    defaultValue={searchKeyword}
                                                    /> 
                                                    {/* <button className='' style={{border:"1px", position:"absolute", top: "20px", right: "15px"}} type='button' onClick={(e)=>{setSearchKeyword(""); getList();}}>X</button> */}
                                                </div>
                                                {/* <div className='col-md-2' >
                                                    City <br/>
                                                    <select className='' id="city" onChange={(e)=>setCity(e.target.value)} style={{padding:"3px", width:"100%"}}>
                                                        <option value={""}>All cities</option>
                                                        {
                                                            cities.map(ct=>{
                                                                return <option value={ct.city}>{ct.city}</option>
                                                            })
                                                                    }

                                                    </select>
                                                </div> */}
                                                {/* <div className='col-md-3'>
                                                    Registration Date <br/>
                                                    <DatePicker 
                                                        className=''
                                                        key = "registrationDate"
                                                        id = "registrationDate"
                                                        placeholder="Enter start time"
                                                        dateFormat="d-MM-yyyy"
                                                        selected={startDate}
                                                        onChange={onChange}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        selectsRange
                                                        autoComplete = "off"
                                                    />
                                                    
                                                        
                                                </div> */}
                                                <div className='col-md-2'><br/>
                                                    <button className='btn btn-sm btn-danger'>Apply</button>
                                                    &nbsp; <button className='btn btn-sm'  type='button' onClick={(e)=>clearFilter(e)}>Reset</button>
                                                </div>
                                                <div className='col-md-2 text-end'><br/>
                                                    <button className='btn btn-sm btn-info'
                                                        onClick={()=>downloadUserData()}
                                                    ><i className='mdi mdi-download'></i> Download</button>
                                                </div>
                                            </form>
                                            <div className='row' style={{marginTop:"20px"}}>
                                                <div className='col-md-12'>
                                                    <Pagination pageInfo={pageInfo} changePage={changePage} />
                                                                
                                                    <table className="table table-striped table-hover table_client">
                                                        <thead>
                                                            <tr className="bg-primary" style={{color:"white"}}>
                                                                <th scope="col">#</th>
                                                                <th scope="col" style={{textAlign:"left"}}>Full Name </th>
                                                                <th scope="col" style={{textAlign:"left"}}>Email </th>
                                                                <th scope="col" style={{textAlign:"left"}}>Phone</th>
                                                                <th scope="col" style={{textAlign:"left"}}>City</th>
                                                                <th scope="col" style={{textAlign:"left"}}>Registered On</th>
                                                                {
                                                                    selectedQuestion.question && (                                      <th scope="col" style={{textAlign:"left"}}>Answer</th>
                                                                    )
                                                                }
                                                            </tr>
                                                        </thead>
                                                        
                                                        <tbody>
                                                        {lists ? (<>
                                                            {lists?.length > 0 ? (<>
                                                                {lists?.map((row, i) => {
                                                                    return(<>
                                                                    <tr style={{color:(row.isWinner>0)?"green":"",fontWeight:(row.isWinner>0)?"bold":""}}>
                                                                        <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++i}</td>
                                                                        <td style={{textAlign:"left"}}>
                                                                            {row?.fullName}
                                                                        </td>
                                                                        <td style={{textAlign:"left"}}>
                                                                            {row?.email}
                                                                        </td>
                                                                        <td style={{textAlign:"left"}}>
                                                                            {row?.phone}
                                                                        </td>
                                                                        <td style={{textAlign:"left"}}>
                                                                            {row?.city}
                                                                        </td>
                                                                        <td style={{textAlign:"left"}}>
                                                                            {DateFunc.format(row?.createdOn*1000,"dd mmm yyyy hh:mm ampm")}
                                                                        </td>
                                                                        {
                                                                    selectedQuestion.question && ( 
                                                                        <td style={{textAlign:"left",color:(row.answer == selectedQuestion.correctOption)?"green":"red"}}>
                                                                            {row?.answer} 
                                                            </td>
                                                                    )}
                                                        </tr>
                                                        </>)
                                                    })}
                                                </>) : (<>
                                                    <tr>
                                                                    <td colSpan={5}>No records found</td>
                                                    </tr>
                                                </>)}
                                            </>) : (<>
                                            
                                            </>)}
                                                
                                            </tbody>
                                        </table>
                                                    <Pagination pageInfo={pageInfo} changePage={changePage} />
                                    </div> 
                                </div>
                                            
                            </div>
                                    </div>
                                </div>
                            </div>



                            
                            
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Players
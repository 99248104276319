import React, { useState, useEffect } from 'react';
//import { defaultPage } from '../../Common/Constant';
import {DateFunc} from '../../Common/Date';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Pagination from '../Layout/Pagination';
import { useNavigate } from "react-router-dom";
import * as QuizApi from '../../api/quiz';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Winnermanager() {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = "Quiz List - QIM - Red FM";
    },);
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();
    const [pageInfo, setPageInfo] = useState({});
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});
    const [startDate, setStartDate] = useState(new Date());//datepicker start date

    const getList = async() =>{
        setErrors();
        let res = await QuizApi.getList({page:0, perPage: 50, searchKeyword: searchKeyword,status:[1,2,3,4,5]});
        if(res.status === 1){
            setLists(res.payload)
            setPageInfo(res.paginationInfo)
        } else{
            setLists([]);
            setErrors({...errors,['records']:"No record found"});
        }        
    }
    useEffect(() => {
        getList();
    },[]);

    
    const updateOrSetStatus = async (id,status) => {
        status = status === 0 ? 1 : 0;
        let res = await QuizApi.updateStatus({id:id,status:status});
        if(res && res.status === 1){
            getList();            
        }
    }

    const trashRecord = async (id) => {
        let res = await QuizApi.trash({ID:id})
        if(res && res.status === 1){
            getList()
        }
    }

    const changePage = (pageInfo) => {
        document.getElementById('quizLink').value = "";        
        document.getElementById('quizText').value = "";   
        document.getElementById('quizLink').disabled = false;
        setPageInfo(pageInfo);
    }

/* ADD MENU SECTION */

    const setFormValues=(data)=>{
        let copy = {
            ID:data.ID,
            title: data.title,
            subTitle: data.subTitle,
            description: data.description,
            duration: data.duration,
            autoStart: data.autoStart,
            resultWaitTime: data.resultWaitTime,
        }
        setForm(copy)
        let date = new Date(data.startOn*1000);
        setStartDate(new Date(date.toString()))
        document.getElementById('autoStart').checked = data.autoStart;
    }
       
    const validateForm = () =>{
        const {name} = form;
        const newErrors = {};
        if(!name || name === '') newErrors.name = 'Please enter this field';
        return newErrors;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors();
        const formData = new FormData(e.target);
        let formInput = {};
        for (const [key, value] of formData) {
            formInput[key] = value;
        }
        // const formErrors = validateForm();
        // if(Object.keys(formErrors).length > 0){
        //     setErrors(formErrors)
        // } else {
            let id= form.ID;
            let res = {};
            if(id && id != null && id!= '' && id != 0){
                formInput.ID = id; console.log(formInput)
                res = await QuizApi.update(formInput);
            }else{
                res = await QuizApi.create(formInput);
            }
            if(res.status === 1){                
                getList();
                setForm({});
                document.getElementById('quizForm').reset();    


            }else {
                setErrors({...errors,['submit']:res.message})
            }
       // }
        
    }

    const handleCancel = () => {   
        document.getElementById('quizForm').reset();    
        setForm({});
    }

/*****END ADD MENU */
    
   
        

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Players & Winners</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item">Quiz</li>
                                                <li className="breadcrumb-item active">Winner manager</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            
                            {/* <!-- end page title --> */}
                            <div className="row mt-0">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            
                                            <table className="table table-striped1 table-hover1 table_client1">
                                                <thead>
                                                    <tr className="" style={{background:"#eee"}}>
                                                        <th scope="col" style={{textAlign:"left"}}>Title</th>
                                                        {/* <th scope="col" style={{textAlign:"left"}}>Description</th>                 */}
                                                        {/* <th scope="col" style={{textAlign:"left"}}>Type</th> */}
                                                        <th scope="col" style={{textAlign:"left"}}>Schedule</th>
                                                        {/* <th scope="col" style={{textAlign:"left"}}>Controls</th>  */}
                                                        <th scope="col" style={{textAlign:"right"}}>Players & Winners</th> 
                                                        {/* <th scope="col" style={{textAlign:"left"}}>Status</th> */}
                                                        {/* <th scope="col">Action</th> */}
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                {lists ? (<>
                                                    {lists?.length > 0 ? (<>
                                                        {lists?.map((row, i) => {
                                                            return(<>
                                                            <tr >
                                                                <td style={{textAlign:"left"}}>
                                                                <span style={{textAlign:"left"}}>{row?.title}</span>
                                                                    <div style={{textAlign:"left","font-size":"11px"}}>
                                                                        <b>Sub Title:</b> {row?.subTitle}
                                                                    </div>
                                                                    <div style={{textAlign:"left","font-size":"11px"}}>
                                                                        <b>Desc:</b> {row?.description}
                                                                    </div>
                                                                    <div style={{textAlign:"left","font-size":"11px"}}>
                                                                    <b>Type:</b> <span style={{textAlign:"left"}}>{row?.type == 1?"Live":(row?.type == 0?"Practice":"Regular (Powerplay)")}</span>
                                                                    </div>
                                                                    <div style={{textAlign:"left","font-size":"11px"}}>
                                                                        <b>Auto Start: </b><span style={{textAlign:"left"}}>{row?.autoStart==1?"Yes":"No"}</span><br/>
                                                                    
                                                                    </div>
                                                                </td>
                                                                {/* <td style={{textAlign:"left"}}>
                                                                    <span style={{textAlign:"left"}}>{row?.description}</span>
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    <span style={{textAlign:"left"}}>{row?.type == 1?"Live":(row?.type == 0?"Practice":"Regular(Powerplay)")}</span>
                                                                </td> */}
                                                                <td style={{textAlign:"left"}}>
                                                                    Schedule: <span style={{textAlign:"left"}}><b>{DateFunc.format(row?.startOn*1000,"dd mmm yyyy hh:mm ampm")}</b></span><br/>
                                                                    Duration: <span style={{textAlign:"left"}}>
                                                                        
                                                                        {/* {row?.duration} */}
                                                                        {
                                                                            Math.floor(row?.duration/(60*60)) > 0 && 
                                                                            (
                                                                                <b>{Math.floor(row?.duration/(60*60))} hr(s)</b>

                                                                            )                                                                            
                                                                        }
                                                                        {
                                                                            Math.floor((row?.duration%(60*60))/60) > 0 && 
                                                                            (
                                                                                <b> &nbsp; {Math.floor((row?.duration%(60*60))/60)} min(s)</b>

                                                                            )                                                                            
                                                                        }
                                                                        {
                                                                            row?.duration%60 > 0 && (
                                                                                <b>
                                                                                &nbsp; {row?.duration%60} sec(s)
                                                                                </b>
                                                                            )
                                                                        }
                                                                    
                                                                    </span>
                                                                    <br/>
                                                                    Result Wait: <span style={{textAlign:"left"}}>
                                                                        {
                                                                            Math.floor(row?.resultWaitTime/60) > 0 && 
                                                                            (
                                                                                <b>{Math.floor(row?.resultWaitTime/60)} min(s)</b>

                                                                            )                                                                            
                                                                        }
                                                                        {
                                                                            row?.resultWaitTime%60 > 0 && (
                                                                                <b>
                                                                                &nbsp; {row?.resultWaitTime%60} sec(s)
                                                                                </b>
                                                                            )
                                                                        }
                                                                        </span>
                                                                    {/* Started: <span style={{textAlign:"left"}}><b>{row?.startDate}</b></span><br/> */}
                                                                </td>
                                                                {/* <td style={{textAlign:"left"}}>
                                                                    Auto Start: <span style={{textAlign:"left"}}><b>{row?.autoStart==1?"Yes":"No"}</b></span><br/> */}
                                                                    {/* Question Gap: <span style={{textAlign:"left"}}>{row?.questionGap}</span><br/> */}
                                                                    {/* Auto Result: <span style={{textAlign:"left"}}>{row?.questionGap}</span><br/> */}
                                                                {/* </td> */}
                                                                <td style={{textAlign:"right"}}>
                                                                    {/* <span style={{color:"blue","text-decoration":"underline","font-weight":"bold","cursor":"pointer"}} onClick={(()=>navigate("/quiz/questions?id="+row.ID+"&quizName="+row.title))}>Winners</span> */}
                                                                    <button  onClick={(()=>window.open("/quiz/winners?id="+row.ID+"&quizName="+row.title+"&pageTitle=Winner List", "_blank", "noreferrer"))} target='_blank' className="btn btn-sm btn-danger"  style={{'font-size':"14px"}}>
                                                                        <i className="mdi mdi-trophy" ></i> Manage/View Winners
                                                                    </button>
                                                                </td>                                                                
                                                               
                                                                {/* <td style={{textAlign:"right"}}>
                                                                    
                                                                    
                                                                    <div className="dropdown align-items-right">
                                                                        <div className="dropdown ms-sm-3 ">
                                                                            <button type="button" className="btn btn-sm " id="page-header-user-dropdown1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{'font-size':"13px", background:"#eee"}}>
                                                                            More <i className="mdi mdi-dots-vertical-circle" ></i>
                                                                            </button>
                                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                                <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>{setFormValues(row);
                                                                                //Util.scrollToTop()
                                                                                }} ><i className="mdi mdi-pencil"></i> Edit</a>
                                                                                <div className="dropdown-divider"></div>
                                                                                <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>trashRecord(row.ID)} ><i className="mdi mdi-delete"></i> Delete</a>
                                                                                                                                                            
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td> */}
                                                            </tr>
                                                            </>)
                                                        })}
                                                    </>) : (<>
                                                        <tr>
                                                            <td colSpan={5} id="noRecordMsg"> <span className="required_field">*{errors?.records?errors.records:"Loading quizs..."}</span></td>
                                                        </tr>
                                                    </>)}
                                                </>) : (<>
                                                </>)}
                                                    
                                                </tbody>
                                            </table>
                                            <Pagination pagination={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Winnermanager
import React, { useState, useEffect } from 'react';
import { categoryDetails, categoryUpdate, getParentCategoriesList } from '../../../api/category';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import Sidebar from '../../Layout/Sidebar';
// import { s3bucket } from '../../../Common/Constant';
// import { uploadImageAPI } from '../../../services/api.services';
import { useNavigate, useLocation } from "react-router-dom";

function CategoryEdit() {
    useEffect(() => {
        document.title = "Edit Category - QIM redFM";
    },);

    const navigate = useNavigate();
    const location = useLocation();
    const queryString = new URLSearchParams(location.search)
    const id = queryString.get('id');
    const [form, setForm] = useState();
    const [errors, setErrors] = useState({});
    const [parentCategories, setParentCategories] = useState([]);

    useEffect(() => {
        getParentCategories()
    }, []);

    const getParentCategories = async () => {
        let res = await getParentCategoriesList();
        if (res.status === 1) {
            setParentCategories(res.payload);
        }
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });
        if (!!errors[field]){
            setErrors({
                ...errors,
                [field]: null
            });            
        }
    }

    const getCategoryDetail = async () => {
        let res = await categoryDetails({payload:id})
        if(res.status === 1){
            setForm({
                ...form,
                ['parentID']: res.payload.parent.ID,
                ['name']: res.payload.name,
                ['priority']: res.payload.priority,
                ['status']: res.payload.status,
                ['imageURL']: res.payload.imageURL
            });
        }
    }
    useEffect(() => {
        getCategoryDetail();
    },[]);

    const validateForm = () =>{
        const {name} = form;
        const newErrors = {};
        if(!name || name === '') newErrors.name = 'Please enter this field';
        return newErrors;
    }
    const handleUpdate = async (e) => {
        e.preventDefault();
        setErrors();
        const formErrors = validateForm();
        if(Object.keys(formErrors).length > 0){
            setErrors(formErrors)
        } else {
            let res = await categoryUpdate({payload: {
                id: id,
                parentID: form.parentID ? form.parentID : 0,
                name: form.name,
                imageURL: null,//form.imageURL && form.imageURL !== '' ? form.imageURL : null,
                priority: form.priority && form.priority !== '' ? form.priority : 0,
                status: form.status
            }});
            if(res.status === 1){
                navigate("/category/list");
            }//  else {
            //     setErrors({...errors,['update']:res.message})
            // }
        }
        
    }

    const handleCancel = () => {
        navigate("/category/list");
    }

    // const handleChange = (e) => {
    //     checkFile(e.target.files);
    // };
    // const checkFile = async (file) => {
    //     if(file.length === 0) return;

    //     // let fileElement = file[0];
    //     // let fileExtension = "";
    //     // if (fileElement.name.lastIndexOf(".") > 0) {
    //     //     fileExtension = fileElement.name.substring(fileElement.name.lastIndexOf(".") + 1, fileElement.name.length);
    //     // }
    //     let res = await uploadImageAPI('/upload/common/', file)
    //     if (res.status === 1) {
    //         setForm({
    //             ...form,
    //             ['imageURL']: res.url
    //         });
    //         if (!!errors['imageURL']) {
    //             setErrors({
    //                 ...errors,
    //                 ['imageURL']: null
    //             });
    //         }

    //         document.getElementById("categoryFileUrl").value = res.url;
    //         document.getElementById('imageView').style.display = 'inline-block';
    //         document.getElementById('imageView').src = s3bucket+res.url;
    //     } else {
    //         setErrors({...errors,['imageURL']:res.message});
    //     }
    // }

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Edit Category</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item">Category</li>
                                                <li className="breadcrumb-item active">Edit Category</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <div className='row'>
                                                <form onSubmit={handleUpdate}>
                                                    {/* <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3 required_field">{errors?.update}</div>
                                                        </div>
                                                    </div> */}
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Parent Category </label>
                                                                <select id="parentCategory" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('parentID',e.target.value)} defaultValue={form?.parentID}>
                                                                    <option value={0} key={0}>Select Parent Category</option>
                                                                    {parentCategories.map((item, indx) => {
                                                                        return <option value={item.ID} key={indx} selected={item.ID === form?.parentID ? true : false}>{item.name}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Category Name 
                                                                    <span className="required_field">*{errors?.name}</span>
                                                                </label>
                                                                <input type="text" className="form-control" placeholder="Enter category name" id="name" defaultValue={form?.name} onChange={(e) => setField('name', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Priority</label>
                                                                <input type="text" className="form-control" placeholder="Enter priority" id="priority" defaultValue={form?.priority} onChange={(e) => setField('priority', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Status</label>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('status',e.target.value)}>
                                                                    <option value={0} key={0} selected={0 === form?.status ? true : false}>Inactive</option>
                                                                    <option value={1} key={1} selected={1 === form?.status ? true : false}>Active</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Image (Dimension: 200 X 200 pixels)</label>
                                                                <input type="file" className="form-control" id="categoryFile" onChange={handleChange} multiple />
                                                            </div>
                                                            <img className="mt-3" id="imageView" src={form?.imageURL ? form?.imageURL : ""} style={{display:'none',height:"50px", border:"2px solid grey"}} />
                                                        </div> */}
                                                    </div>
                                                    {/* <!--end row--> */}

                                                    <div className="row text-end d-inline">
                                                        <div className="col-md-12">
                                                            <button type="button" className="btn btn-primary mx-2" id="updateButton" onClick={handleUpdate}>Update</button>
                                                            <button type="button" className="btn btn-danger mx-2" id="cancelButton" onClick={handleCancel}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CategoryEdit
import React, { useState, useEffect } from 'react';
//import { defaultPage } from '../Common/Constant';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {DateFunc} from '../../Common/Date';
import * as QuestionApi from '../../api/question';
import { masterData } from '../../api/master-data';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Pagination from '../Layout/Pagination';
import { isDisabled } from '@testing-library/user-event/dist/utils';

function Question() {
    useEffect(() => {
        document.title = "Question - QIM RedFm"
    },);
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();
    const [pageInfo, setPagination] = useState({});
    const [showOption, setShowOption] = useState(true)
    // const [countries, setCountries] = useState();
    // const [languages, setLanguages] = useState();
    const [controlType, setControlType] = useState()
    const [submitBtnTxt, setSubmitBtnTxt] = useState('Submit')
    const [queId, setQueId] = useState()
    // const [countryId, setCountryId] = useState()
    // const [languageId, setLanguageId] = useState()
    const [controlTypeId, setControlTypeId] = useState(34)
    const [questionType, setQuestionType] = useState('')
    const [title, setTitle] = useState();
    const [editFormTitle, setEditFormTitle] = useState()
    const [question, setQuestion] = useState("")
    const [explanation, setExplanation] = useState("")
    const [addOption, setAddOption] = useState()
    const [mappedOption, setMappedOption] = useState([])
    const [correctOption, setCorrectOption] = useState("")
    const [errors, setErrors] = useState({});
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        getList();
    },[]
    //,[countryId, languageId]
    )

    useEffect(() => {
        //getCountries()
        // getLanguages()
        getControlType()
    },[])

    // useEffect(() => {
    //     getLanguages()
    // },[countryId])

    // const getCountries = async() => {
    //     let res = await masterData({moduleName:'country'})
    //     if(res.status === 1){
    //         setCountries(res.payload)
    //     }
    // }

    // const getLanguages = async() => {
    //     let res = await masterData({moduleName:'language',countryID:countryId})
    //     if(res.status === 1){
    //         setLanguages(res.payload)
    //     }
    // }

    const getControlType = async() => {
        let res = await masterData({typeSlug:'control-type'})
        if(res?.status === 1){
            setControlType(res.payload)
        }
    }

    const getList = async() =>{
        //if(countryId && languageId){
        let data = {
            page: pageInfo.current_page,
            perPage: 50,
            q: searchKeyword,
            // countryID: countryId,
            // languageID: languageId
        }

            let res = await QuestionApi.getList(data);
            //let res = await get('/question/list', data)

            if(res.status === 1){
                setLists(res.payload)
                setPagination(res.paginationInfo)
            }else{
                setLists([])
            }
        //}
        
    }

    const handleControlType = (value) => {
        setShowOption(true)
        if (value === '' || value === null || value === '1272' || value === 1272) {
            setShowOption(false)
        }
        setControlTypeId(value)
    }

    // const checkUnique = async () => {
    //     setErrors({...errors, ['title']: null})
    //     if(languageId && title && title !== editFormTitle){
    //         let data = {
    //             languageID: parseInt(languageId),
    //             title: title
    //         }
    //         let res = await Question.CheckUniqueTitle({payload:data})
    //         if(res.status === 0){
    //             setErrors({...errors, ['title']: res.message})
    //         }
    //     }
    // }

    const handleAddOption = (e) => {
        setAddOption(e.target.value)
        // setForm({...form, ['description']:e.target.value})
    }

    const removeMapped = (item) => {
        let mappedList = mappedOption.filter(maped => maped != item);
        setMappedOption(mappedList);
    }
    
    const moveToMappedOption = async () => {
        addOption?.split('\n').map(function (item, i) {
            if (item !== '' || item !== null) {
                if (mappedOption?.indexOf(item) === -1) {
                    setMappedOption((mappedOption) => [...mappedOption, item])
                }
            }

        })
        await setAddOption("")
    }

    const handlesubmit = async (e) => {
        e.preventDefault();
        setErrors();
        let formData = {
            options:mappedOption,
            correctOption,
            // countryID:countryId,
            // languageID:languageId,
            controlTypeID:controlTypeId,
            questionType,
            title,
            explanation,
            question,
            description:null
        };
        //const formErrors = Question.validateFormData(formData)
        // if(Object.keys(formErrors).length > 0){
        //     setErrors(formErrors)
        // }else{
            let res = {};
            if(queId && queId != "" && queId != 0){
                res = await QuestionApi.update({ID:queId,payload:formData});
            }else{
                res = await QuestionApi.create({payload:formData});
            }
            if(res.status === 1){                
                //setControlTypeId();
                //setTitle("");
                setQuestion("")
                setExplanation("")
                setQuestionType("")
                setAddOption()
                setMappedOption([])
                getList();
                document.getElementById("questionTypeInput").value="";
            }else{
                setErrors({...errors,['submit']:res.message})
            }
        //}
    }
    const handleQueTitle = async (e) => {
        if(!/[0-9a-zA-Z_]/.test(e.key)){
            e.preventDefault();
        }
    }

    
    const editForm = async(row) => {
        //let res = await QuestionApi.getDetail({payload:queId})
        // if(res.status === 1){
            let data = row;//res.payload;
            setQueId(data.ID)
            // setCountryId(data.countryID)
            // setLanguageId(data.languageID)
            //setControlTypeId(data.controlTypeID)
            //setTitle(data.title)
            //setEditFormTitle(data.title)
            setQuestionType(data.questionType);
            setQuestion(data.question);
            setExplanation(data.explanation);

            let opsDt = []
            data.options.forEach(ele => {
                opsDt.push(ele?.option)
            });
            setMappedOption(opsDt)
            setCorrectOption(data.correctOption);
      
            //setSubmitBtnTxt('Update')
            
            // let controlTypeID = data?.controlTypeID
            // setShowOption(true)
            // if(controlTypeID === '' || controlTypeID === null || controlTypeID === '1272' || controlTypeID === 1272){
            //     setShowOption(false)
            // }
            //setControlTypeId(controlTypeID);

            // scroll to top
            window.scrollTo(0, 0)
        //}


    }

    const trashRecord = async (id) => {
        let res = await QuestionApi.trash({ID:id})
        if(res.status === 1){
            getList()
        }
    }

    const changePage =(p)=>{
        pageInfo.current_page = p;
        getList();
    }

    const resetForm = () => {
        setQueId()
        // setCountryId()
        // setLanguageId()
        setControlTypeId()
        setTitle()
        document.getElementById('title').value=""
        setQuestion("")
        document.getElementById('question').value=""
        setAddOption("")
        setShowOption(false)
        setMappedOption([])
        setSubmitBtnTxt('Submit')
        setErrors({})
    }

    const updateOrSetStatus = async (id,status) => {
        status = status === 0 ? 1 : 0;
        let res = await QuestionApi.updateStatus({id:id,status:status});
        if(res && res.status === 1){
            getList();            
        }
    }

    const toggleForm = async(e) => {
        if(showForm === true){
            setShowForm(false);
            e.target.innerHTML = "Add Question +";
        }else{
            setShowForm(true);
            e.target.innerHTML = "Hide Form -";
        }
    }
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Question</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item active">Question</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body" style={{minHeight:"45px"}}>

                                        <button style={{position:"absolute",right:"10px",top:"5px"}} className='btn btn-danger' onClick={(e)=>toggleForm(e)}>Add Question + </button>
                                            <div className="">

                                                {
                                                    showForm && (
                                                        <>
                                                            <form  className="mt-3" onSubmit={handlesubmit} >
                                                    <div className="row">
                                                        {/* <!--end col--> */}
                                                        {/* <div className="col-md-4" style={{display:"none"}}>
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0"> Country <span className="required_field">*{errors?.countryID}</span></label>
                                                                <select id="country" name='country' className="form-select" data-choices data-choices-sorting="true" >
                                                                    <option value={""} disabled selected>Select Country</option>
                                                                    {countries?.map((row) => {
                                                                        return (<option value={row.id} selected={row.id === countryId ? true : false}>{row.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0"> Language <span className="required_field">*{errors?.languageID}</span></label>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" 
                                                                    onChange={(e) => setLanguageId(e.target.value)}
                                                                    disabled={countryId ? false : true}>
                                                                    <option value={""} disabled selected>Select Language</option>
                                                                    {languages?.map((row) => {
                                                                        return (<option value={row.id} selected={row.id === languageId ? true : false}>{row.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div> */}
                                                        <div className="col-md-2" >
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Control Type <span className="required_field">*{errors?.questionType}</span></label>
                                                                <select className="form-select" onChange={(e) => handleControlType(e.target.value) } disabled >
                                                                    <option value={""} selected ={true} >Select Control</option>
                                                                    {controlType?.map((row) => {                                                                    
                                                                        return (<><option value={row.ID} selected={row.ID == controlTypeId ? true : false}>{row.name}</option></>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Question Type <span className="required_field">*{errors?.controlTypeID}</span></label>
                                                                <select id="questionTypeInput" className="form-select" onChange={(e) => setQuestionType(e.target.value) } required >
                                                                    <option value={""} selected ={true} >Select Question Type</option>
                                                                    <option value="1" selected ={questionType===1?true:false} >Live</option>
                                                                    <option value="0" selected ={questionType===0?true:false} >Sample</option>
                                                                </select>
                                                            </div>                                                            
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        {/* <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="title" className="form-label mb-0">Question no.<span className="required_field">*{errors?.title}</span></label>
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    placeholder="Enter question no." 
                                                                    id="title" 
                                                                    disabled={countryId && languageId ? false:true} 
                                                                    value={title}
                                                                    onKeyDown={(e) => handleQueTitle(e)}
                                                                    onChange={(e) => setTitle(e.target.value)} 
                                                                    onBlur={() => checkUnique()} />
                                                            </div>
                                                        </div> */}
                                                        <div className="col-md-10">
                                                            <div className="mb-3">
                                                                <label htmlFor="question" className="form-label mb-0">Question <span className="required_field">*{errors?.question}</span></label>
                                                                <ReactQuill style={{height:"69px"}} theme="snow" placeholder="Enter Question" id="question"  value={question} onChange={(e) => setQuestion(e)} required />
                                                            </div>

                                                        </div>
                                                        
                                                        {showOption ? (<>
                                                            <div className="form_divide"><hr ></hr></div>
                                                            <div className="col-md-5">
                                                                <div className="mb-3">
                                                                    <label htmlFor="addOption" className="form-label mb-0">Add Option <span className="required_field">*{errors?.description}</span></label>
                                                                    <textarea className="form-control" id="addOption" rows="5" onChange={(e) => handleAddOption(e)} value={addOption}></textarea>
                                                                    <span className="max_char">Maximum limit of characters 500</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-1" style={{padding:"45px 0 0 0px"}}>
                                                                <div className="text-center mt-3">
                                                                    <button type='button' className="btn add_qustion btn-primary" style={{color:"white", width:"80px"}} onClick={() => moveToMappedOption()}>Add &gt;&gt; </button>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label mb-0">Mapped Option <span className="required_field">*{errors?.options}</span></label>
                                                                    
                                                                    <div style={{height:"115px", overflowY:"scroll", border:"1px solid", padding: "0.5rem 0.9rem", borderColor:"#ced4da", borderRadius:"4px"}}>
                                                                    {mappedOption?.map((item) => {
                                                                        return (<><div style={{cursor:"pointer","padding":"5px"}} >
                                                                        <input type="radio" name='correctOption' style= {{color:"red",border:"1px solid red","font=weight":"bold",cursor:"pointer",padding:"0 5px"}} onClick={() => setCorrectOption(item)} value={item} defaultChecked={correctOption==item?true:false}/>   &nbsp; &nbsp;
                                                                            <div style={{display:"inline-block",marginRight:"30px"}}>{item}</div> 
                                                                        <span style= {{color:"red",border:"1px solid red",borderRadius:"3px","font=weight":"bold",cursor:"pointer",padding:"0 5px"}} onClick={() => removeMapped(item)}>x</span>
                                                                        </div></>)
                                                                    })}
                                                                    </div>
                                                                    <span className="max_char">Maximum limit of characters 500</span>
                                                                </div>
                                                            </div>                              
                                                        </>) : (<>
                                                            
                                                        </>)} 
                                                        <div className="col-md-9">
                                                            <div className="mb-3">
                                                                <label htmlFor="question" className="form-label mb-0">Explanation <span className="required_field">*{errors?.explanation}</span></label>
                                                                <ReactQuill style={{height:"69px"}} theme="snow" placeholder="Explanation of correct answer" id="explanation"  value={explanation} onChange={(e) => setExplanation(e)}  required />
                                                            </div>
                                                        </div>                                                   
                                                        <div className="col-lg-12">
                                                            <div className="text-end">
                                                                <button type='submit' className="btn btn-primary mx-4" >Submit</button>
                                                                <a className="btn btn-danger" onClick={() => resetForm()}>
                                                                    <i className="mdi mdi-refresh"></i> Reset
                                                                </a>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                </form>
                                                        </>
                                                    )
                                                }                                                
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end col --> */}
                            </div>
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-striped1 table-hover1 table_client1">
                                                <thead>
                                                    <tr className="bg-grey">
                                                        {/* <th scope="col">Sr. No.</th> */}
                                                        {/* <th scope="col" style={{textAlign:"left"}}>Question no.</th> */}
                                                        <th scope="col" style={{textAlign:"left",width:"300px"}}>Question</th>
                                                        {/* <th scope="col" style={{textAlign:"left",width:"100px"}}>Type</th> */}
                                                        {/* <th scope="col" style={{textAlign:"left",width:"150px"}}>Control Type</th> */}
                                                        <th scope="col" style={{textAlign:"left"}}>Available Options</th>
                                                        <th scope="col" style={{textAlign:"left",width:"150px"}}>Created On</th>
                                                        <th style={{textAlign:"right",minWidth:"200px"}}>Action</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                {lists ? (<>
                                                    {lists?.length > 0 ? (<>
                                                        {lists?.map((row, index) => {
                                                            return(<>
                                                            <tr>
                                                            {/* <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++index}</td> */}
                                                               
                                                                {/* <td style={{textAlign:"left"}}>
                                                                    <strong><span style={{textAlign:"left"}}>{row?.title}</span></strong>
                                                                </td> */}
                                                                <td style={{textAlign:"left"}} 
                                                                dangerouslySetInnerHTML={{
                                                                    __html: row?.question
                                                                    +`<div style='font-size:11px'><b>Type: `+(row?.questionType==1?"Live":"Sample")+`</b></div>`
                                                                    +`<div style='font-size:11px'><b>Control Type: `+row?.controlTypeName+`</b></div>`
                                                                }}>

                                                                </td>
                                                                {/* <td style={{textAlign:"left"}}>{row?.questionType==1?"Live":"Sample"}</td> */}
                                                                {/* <td style={{textAlign:"left"}}>{row?.controlTypeName}</td> */}
                                                                {/* show comma separated options */}
                                                                <td style={{textAlign:"left"}} width="30%">
                                                                    {row?.controlTypeSlug !== 'textbox' ? (<>
                                                                        {row?.options?.map((item, i) => {
                                                                            let correct = false;
                                                                            //for(let j=0; j <row.correctOption.length; j++){
                                                                                if(item?.option == row.correctOption){
                                                                                    correct = true;
                                                                                }
                                                                            //}
                                                                            return (
                                                                                <span style={{"color":(correct)?"green":"","fontWeight":(correct)?"bold":""}}>
                                                                                    - {item.option}  
                                                                                    &nbsp; {correct === true?(<i className='mdi mdi-checkbox-marked-outline'></i>):""}
                                                                                <br/>
                                                                                </span>
                                                                            )
                                                                        })}
                                                                    </>) : (<>
                                                                        <span>-</span>
                                                                    </>)}
                                                                    {row.explanation != null && (
                                                                        <>
                                                                            <div style={{fontSize:"11px"}} dangerouslySetInnerHTML={{__html: '<b>Explanation:</b> '+row?.explanation}}></div>
                                                                        </>
                                                                    )

                                                                    }
                                                                </td>
                                                                <td style={{textAlign:"left"}}>{DateFunc.format(row?.createdOn*1000,"dd mmm yyyy hh:mm ampm")}</td>                                                                
                                                                <td style={{textAlign:"right"}}>
                                                                    {/* <span className="p-2 font-16" title="Edit question">
                                                                        <i className="ri-edit-2-line pointerC" onClick={() => editForm(row?.ID)}></i>
                                                                    </span>
                                                                    <span className="p-2 font-16" title="Delete question">
                                                                        <i className="ri-delete-bin-2-line del pointerC" onClick={() => trashRecord(row?.ID)}></i>
                                                                    </span> */}
                                                                    <div className="form-check form-switch" style={{textAlign:'right', display:"inline-block"}} >
                                                                                    <input className="btn-lg form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={row.status === 1 ? "checked" : ""} onChange={()=>updateOrSetStatus(row.ID, row.status)} /> Status
                                                                                 </div>  &nbsp; &nbsp;
                                                                    <div className="dropdown align-items-right" style={{display:"inline-block"}}>
                                                                        <div className="dropdown ms-sm-3 ">
                                                                            <button type="button" className="btn btn-sm " id="page-header-user-dropdown1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{'font-size':"13px", background:"#eee"}}>
                                                                            More <i className="mdi mdi-dots-vertical-circle" ></i>
                                                                            </button>
                                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                                <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>{editForm(row);
                                                                                //Util.scrollToTop()
                                                                                }} ><i className="mdi mdi-pencil"></i> Edit</a>
                                                                                <div className="dropdown-divider"></div>
                                                                                <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>trashRecord(row.ID)} ><i className="mdi mdi-delete"></i> Delete</a>   
                                                                                <div className="dropdown-divider"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </>)
                                                        })}
                                                    </>) : (<>
                                                        <tr>
                                                            <td colSpan={6}>No data found</td>
                                                        </tr>
                                                    </>)}
                                                </>) : (<>
                                                
                                                </>)}
                                                    
                                                </tbody>
                                            </table>
                                            <Pagination pageInfo={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Question
import React, { useState, useEffect, useRef } from 'react';
import { defaultPage } from '../../Common/Constant';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Pagination from '../Layout/Pagination';
import { useNavigate, useLocation } from "react-router-dom";
import * as Question from '../../api/question';
import * as QuizApi from '../../api/quiz';




function QuizQuestions() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryString = new URLSearchParams(location.search)
    const id = queryString.get('id'); //console.log(id);  
    const quizName = queryString.get('quizName'); //console.log(id);  

    useEffect(() => {
        document.title = "Quiz Questions - QIM - Red FM";
    },);
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState(); //question bank list
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [pageInfo, setPageInfo] = useState({});
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});

    const getList = async() =>{ //Question bankmlist
        setErrors();
        let res = await Question.getList({page:0, perPage: 50, searchKeyword: searchKeyword, status:1});
        if(res.status === 1){
            setLists(res.payload)
            setPageInfo(res.paginationInfo)
        } else{
            setLists([]);
            setErrors({...errors,['records']:"No record found"});
        }        
    }

    const getQuestionSetList = async() =>{ //Question selected for the quiz list
        setErrors();
        let res = await QuizApi.getQuestionSetList({id:id, page:0, perPage: 50});
        if(res.status === 1){
            setSelectedQuestions(res.payload)
        } else{
            setSelectedQuestions([]);
            setErrors({...errors,['records']:"No record found"});
        }        
    }
    useEffect(() => {
        getList();
        getQuestionSetList();
    },[]);

    
    const updateOrSetStatus = async (id,status) => {
        status = status === 0 ? 1 : 0;
        let res = await QuizApi.updateStatus({id:id,status:status});
        if(res && res.status === 1){
            getList();            
        }
    }

    const deleteRow = async (id) => {
        let res = await QuizApi.trash({id:id})
        if(res && res.status === 1){
            getList()
        }
    }

    const changePage = (pageInfo) => {
        document.getElementById('quizLink').value = "";        
        document.getElementById('quizText').value = "";   
        document.getElementById('quizLink').disabled = false;
        setPageInfo(pageInfo);
    }

/* ADD MENU SECTION */

    const setFormValues=(data)=>{
        let copy = {
            name: data.name,
            ID: data.ID
        }
        setForm(copy)
    }
       
    const validateForm = () =>{
        const {name} = form;
        const newErrors = {};
        if(!name || name === '') newErrors.name = 'Please enter this field';
        return newErrors;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors();
        const formData = new FormData(e.target);
        let formInput = {};
        for (const [key, value] of formData) {
            formInput[key] = value;
        }
        // const formErrors = validateForm();
        // if(Object.keys(formErrors).length > 0){
        //     setErrors(formErrors)
        // } else {
            let id= form.ID;
            let payload= form;
            let res = {};
            if(id && id != null && id!= '' && id != 0){
                payload.id = id;
                res = await QuizApi.update({payload});
            }else{
                res = await QuizApi.create({payload:formInput});
            }
            if(res.status === 1){                
                getList();
                setForm();
            }else {
                setErrors({...errors,['submit']:res.message})
            }
       // }
        
    }

    const handleCancel = () => {   
        document.getElementById('quizForm').reset();    
        setForm({});
    }

    const addSelectedQuestions= (e,q) =>{
        let selected = selectedQuestions;
        selected.push({ID:q.ID,question:q.question,duration:60});
        setSelectedQuestions([...selected]);
        //e.target.style.display = "none";
        document.getElementById("q_row_"+q.ID).style.display = "none";

    }

    const resetDuration = (e,ID) => {
        let selected = selectedQuestions;
        for(let i =0; i < selected.length; i++){
            if(selected[i].ID == ID){
                selected[i].duration = e.target.value; break;
            }
        };
        setSelectedQuestions([...selected]);
        console.log(selectedQuestions);
    }

    const saveQuizQuestions = async() => {
        let payload = {id, selectedQuestions};
        let res = {};
        res = await QuizApi.setQuestions({payload});            
        if(res.status === 1){                
            getList();
            setForm();
        }else {
            setErrors({...errors,['submit']:res.message})
        }
    }

    const removeMapped = (item) => {
        let selected = selectedQuestions.filter(maped => maped != item);
        setSelectedQuestions([...selected]);
        console.log(item.ID);
        document.getElementById("q_row_"+item.ID).style.display = "table-row";
    }

    const dragItem = useRef();
    const dragOverItem = useRef();

    const dragStart = (e, position) => {
        dragItem.current = position;
    };
    
    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };
     
    const drop = (e) => {
         const dragItemContent = selectedQuestions[dragItem.current];
         selectedQuestions.splice(dragItem.current, 1);
         selectedQuestions.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setSelectedQuestions([...selectedQuestions]);
        //resetPrirority();
    };

    // const resetPrirority=async()=>{
    //     let priorities = []
    //     itemLists.map(item=>{
    //         priorities.push(item.ID);
    //     })
    //     Menu.updateItemPriority(priorities);
    // }
    
   
        

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row" >
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0"> <span style={{fontWeight:400,cursor:"pointer"}} onClick={()=>navigate("/quiz")}>[<i className='mdi mdi-arrow-left'></i> BACK]</span> &nbsp; Quiz Questions: <span style={{color:"orange"}}>{quizName}</span> </h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item">Quiz</li>
                                                <li className="breadcrumb-item active">Manage Quiz</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            
                            {/* <!-- end page title --> */}
                            
                            <div className="row mt-0">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">                                            
                                            {/* <div className='row'>
                                                <form onSubmit={handleSubmit} id="quizForm">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3 required_field" required="1">{errors?.submit}</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">QuestionDuration in Seconds
                                                                    <span className="required_field">*{errors?.duration}</span>
                                                                </label>
                                                                <input type="text" key = "duration" className="form-control" placeholder="Enter quiz duration" id="duration" name="duration" defaultValue={form?.duration?form.duration:""}  />
                                                            </div>
                                                        </div>                                                        
                                                        <div className="col-md-2">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Question Time Gap 
                                                                    <span className="required_field">*{errors?.questionGap}</span>
                                                                </label>
                                                                <input type="text" key = "questionGap" className="form-control" placeholder="Enter time gap between questions" id="questionGap" name='questionGap' defaultValue={form?.questionGap?form.questionGap:""}  />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 mt-3">
                                                            <button type="submit" className="btn btn-primary mx-2" id="submitButton" >Submit</button>
                                                            <button type="button" className="btn btn-danger mx-2" id="cancelButton" onClick={handleCancel}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div> */}
                                             <div className='row'>
                                                <div className='col-md-6' >
                                                    
                                                    <strong>Question Bank: {lists?.length} questions</strong> (Click to select)
                                                    <div style={{border:"2px solid #cccc",height:"400px",overflow:"auto"}}>
                                                        <table className="table table-striped1 table-hover">                                                        
                                                            <tbody>
                                                            {lists ? (<>
                                                                {lists?.length > 0 ? (<>
                                                                    {lists?.map((row, index) => {
                                                                        return(<>
                                                                        <tr id={"q_row_"+row.ID} onMouseOver={(e)=>{e.target.style.cursor='pointer'}} onClick={(e)=>addSelectedQuestions(e,row)}>
                                                                            <td style={{textAlign:"left"}} dangerouslySetInnerHTML={{__html: row?.question}}></td>
                                                                        </tr>
                                                                        </>)
                                                                    })}
                                                                </>) : (<>
                                                                    <tr>
                                                                        <td colSpan={6}>No question found</td>
                                                                    </tr>
                                                                </>)}
                                                            </>) : (<>
                                                            
                                                            </>)}
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div  className='col-md-6'>    
                                                <strong>Selceted Question: {selectedQuestions?.length} questions</strong>
                                                    <div style={{border:"2px solid #cccc",height:"400px",overflow:"auto"}}>
                                                        <div style={{background:"#eee",padding:"4px"}}>
                                                        <div style={{display:"inline-block",width:"78%",verticalAlign:"top"}}> &nbsp; <b>Question</b></div>
                                                        <div style={{display:"inline-block",width:"14%",verticalAlign:"top"}}><b>Duration</b></div>
                                                        <div style={{display:"inline-block",width:"6%",verticalAlign:"top"}}></div>
                                                        </div>
                                                        <table className="table table-striped1 table-hover">                                                        
                                                            <tbody>
                                                            {selectedQuestions ? (<>
                                                                {selectedQuestions?.length > 0 ? (<>
                                                                    {selectedQuestions?.map((row, index) => {
                                                                        return(<>
                                                                        <tr key={"selected-item"+index} id={"selected-item"+index} 
                                                                            onDragStart={(e) => dragStart(e, index)}
                                                                            onDragEnter={(e) => dragEnter(e, index)}
                                                                            onDragEnd={drop}
                                                                            draggable >
                                                                            <td style={{textAlign:"left"}}>
                                                                                <div style={{display:"inline-block",width:"78%",verticalAlign:"top"}} dangerouslySetInnerHTML={{__html: row?.question}}></div>
                                                                                <div style={{display:"inline-block",width:"14%",verticalAlign:"top"}}>
                                                                                    <input style={{width:"100%"}} defaultValue={row.duration} onKeyUp={(e)=>resetDuration(e,row?.ID)} />
                                                                                </div>
                                                                                <div style={{display:"inline-block",width:"6%",verticalAlign:"top"}}>
                                                                                    &nbsp;<div style= {{display:"inline-block",color:"red",border:"1px solid red",borderRadius:"3px","font=weight":"bold",cursor:"pointer",padding:"2px 7px"}} onClick={() => removeMapped(row)}>x</div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        </>)
                                                                    })}
                                                                </>) : (<>
                                                                    <tr>
                                                                        <td colSpan={6}>No question found</td>
                                                                    </tr>
                                                                </>)}
                                                            </>) : (<>
                                                            
                                                            </>)}
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div> <br/>
                                                    <button className='btn  btn-danger' style={{}} onClick={()=>saveQuizQuestions()}>Save</button>                                                       
                                                </div>
                                            </div>
                                            <Pagination pagination={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default QuizQuestions
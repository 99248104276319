import React, { useState, useEffect } from 'react';
import { masterData } from '../../../api/master-data';
import { mappedCategoryAttributeSet, mapCategoryAttributeSet } from '../../../api/category';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import Sidebar from '../../Layout/Sidebar';
import { useNavigate, useLocation } from "react-router-dom";

function CategoryMappingWithAttributeSet() {
    useEffect(() => {
        document.title = "Category mapping - QIM redFM";
    },);

    const navigate = useNavigate();
    const location = useLocation();
    const queryString = new URLSearchParams(location.search)
    const id = queryString.get('id');
    const [mappedAttributeSet, setMappedAttributeSet] = useState([])

    const [attributeSet, setAttributeSet] = useState();
    const [attributeSetID, setAttributeSetID] = useState();
    useEffect(() => {
        getAttributeSet();
    },[])

    const getAttributeSet = async() => {
        let res = await masterData({typeSlug: 'attribute-set'});
        if(res.status === 1){
            setAttributeSet(res.payload)
        }
    }

    useEffect(() => {
        getMappedCategoryAttributeSet();
    },[]);

    const getMappedCategoryAttributeSet = async () => {
        let res = await mappedCategoryAttributeSet({id:id});
        if(res.status === 1){
            let attrLstID = [];
            await res.payload?.forEach((attr) => {
                attrLstID.push(attr.ID.toString());
            });
            setMappedAttributeSet(attrLstID);
        }
    }

    const handleMapping = async (e) => {
        e.preventDefault();
        let res = await mapCategoryAttributeSet({id:id, attributeSetIDArr: mappedAttributeSet});
        if(res.status === 1){
            navigate("/category/list");
        }        
    }

    const handleCancel = () => {
        navigate("/category/list");
    }

    const resetMappedAttributeSet = (value) => {
        if(mappedAttributeSet.includes(value)){
            let index = mappedAttributeSet.indexOf(value);
            if (index > -1) {
                mappedAttributeSet.splice(index, 1);
            }
        } else {
            mappedAttributeSet.push(value);
        }
        setMappedAttributeSet(mappedAttributeSet);
    }

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Category mapping with attribute set</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item">Category</li>
                                                <li className="breadcrumb-item active">Category mapping with attribute set</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <div className='row'>
                                                <form onSubmit={handleMapping}>
                                                    <div className="row">
                                                        { attributeSet && attributeSet.length > 0 && attributeSet.map((item) => (
                                                            <div className="col-md-4 font-18" key={item.ID}>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" id={"checkbox-"+item.ID} defaultChecked ={mappedAttributeSet?.includes(item.ID.toString()) ? true : false} onChange={(e) => resetMappedAttributeSet(e.target.value)} value={item.ID} />
                                                                    <label className="form-check-label" htmlFor={"checkbox-"+item.ID}>
                                                                        {item.name}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="row text-end d-inline">
                                                        <div className="col-md-12">
                                                            <button type="button" className="btn btn-primary mx-2" id="updateButton" onClick={handleMapping}>Submit</button>
                                                            <button type="button" className="btn btn-danger mx-2" id="cancelButton" onClick={handleCancel}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CategoryMappingWithAttributeSet
import { post } from "../services/api.services";
const { defaultPage } = require('../Common/Constant');

export const masterData = async({typeSlug, pagePerRec=defaultPage.perPage, page=defaultPage.current_page, searchText="", parentID=""}) => {
    try {
        let payloadData = {
            typeSlug: typeSlug,
            page: page,
            perPage: pagePerRec,
            q: searchText,
            parent: parentID
        }
        let data = await post('/master-data/list/', payloadData)
        if (data.status === 1) {
            return data;
        } else {
            return [];
        }
    } catch (err) {
        console.log(err);
        return [];
    }
}
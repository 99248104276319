
import './App.css'
import React from 'react'
import {Routes, Route} from 'react-router-dom';
import LoginPage from './component/LoginPage';
import Dashboard from './component/Dashboard';
import PageNotFound from './component/PageNotFound';

// ERP - START 

import CategoryList from './component/Erp/Category/List';
import CategoryAdd from './component/Erp/Category/Add';
import CategoryEdit from './component/Erp/Category/Edit';
import CategoryMappingWithAttributeSet from './component/Erp/Category/MappingWithAttributeSet';
// ERP - END 

// Quiz - START
import Quiz from './component/Quiz/Quiz';
import QuizQuestions from './component/Quiz/quizQuestions';
import PlayQuiz from './component/Quiz/PlayQuiz';
import Question from './component/Quiz/Question';
import UserQuestion from './component/Quiz/UserQuestion';
import Winner from './component/Quiz/Winner';
import Winnermanager from './component/Quiz/Winnermanager';
import Players from './component/Quiz/Players';

// Quiz - END

import User from './component/Crm/Users/User';
import Lead from './component/Crm/Users/Lead';

function App() {
  return (
    <div className="App">
      <>     
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='/dashboard' element={<Dashboard />} />

          {/* CRM - START */}
          <Route path='/leads/' element={<Lead />} />
          <Route path='/users/' element={<User />} /> 
          {/* CRM - END

          {/* ERP - START */}          
          <Route path='/category/list' element={<CategoryList />} />
          <Route path='/category/add' element={<CategoryAdd />} />
          <Route path='/category/edit' element={<CategoryEdit />} />
          <Route path='/category/attribute-set/mapping' element={<CategoryMappingWithAttributeSet />} />

          {/* ERP - END */}

          {/* Quiz - START */}
          <Route path='/quiz' element={<Quiz/>} />       
          <Route path='/quiz/winners' element={<Winner/>} />       
          <Route path='/quiz/winner-manager' element={<Winnermanager/>} />       
          <Route path='/quiz/questions' element={<QuizQuestions/>} />       
          <Route path='/quiz/play' element={<PlayQuiz/>} />   
          <Route path='/quiz/players' element={<Players/>} />   
          <Route path='/question' element={<Question/>} />   
          <Route path='/user-submitted-questions' element={<UserQuestion/>} />   
              
         
          {/* Quiz - END */}


          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </>
    </div>
  );
}

export default App;

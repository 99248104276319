import React, { useState, useEffect } from 'react';
import { defaultPage } from '../../../Common/Constant';
import {DateFunc} from '../../../Common/Date';
import { leadList } from '../../../api/user';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import Sidebar from '../../Layout/Sidebar';
import Pagination from '../../Layout/Pagination';
import { get, getDownloadFile } from "../../../services/api.services";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Lead() {

    useEffect(() => {
        document.title = "Subscriber List - QIM redFM";
    },);


    const [searchKeyword, setSearchKeyword] = useState("");  
    //const [startDate, setStartDate] = useState();//useState(new Date());//datepicker start date
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const onChange = (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };
    const [lists, setLists] = useState();
    const [pageInfo, setPageInfo] = useState({});
    const [errors, setErrors] = useState({});
    const [registrationData, setRegistrationData] = useState([]);
    

    useEffect(() => {
        getList();
    },[searchKeyword, startDate, endDate]);

    const getList = async() =>{
        let res = await leadList({page: pageInfo.current_page, perPage: defaultPage.perPage, q: searchKeyword, startDate, endDate});
        if(res.status === 1){
            setLists(res.payload)
            setPageInfo(res.paginationInfo)
        } else{
            setLists([])
        }
    }

    const changePage = (p) => {
        pageInfo.current_page = p;
        getList();
    }

    const applyFilter=async(e)=>{
        e.preventDefault();
        getList();
    }

    const clearFilter=()=>{
        document.getElementById('registrationDate').value="";
        setStartDate("");
        setEndDate("");
        getList();
    }

    const downloadUserData = async () => {
        let startDate1 = "";
        let endDate1 = "";
        if(startDate != "" && startDate != null){
            //const date = new Date();
             startDate1 = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
        } 
        if(endDate != "" && endDate != null){
            //const date = new Date();
             endDate1 = `${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}`;
        } 
        await getDownloadFile("/user/completed/list/download?page="+pageInfo.current_page+"&perPage="+defaultPage.perPage+"&q="+searchKeyword+"&startDate="+startDate1+"&endDate="+endDate1, `Leads ${startDate1} - ${endDate1}`);
    };
          

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Leads</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item active">CRM</li>
                                                <li className="breadcrumb-item active">Leads</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            
                                            <form className='row' onSubmit={applyFilter}>
                                                <div className='col-md-3'>
                                                    Search <br/>
                                                    <input className='' 
                                                    style={{width:"100%", display:"inline-block"}}
                                                    placeholder='by name, mobile, email' 
                                                    id="searchKeyword"  
                                                    onKeyUp={(e)=>setSearchKeyword(e.target.value)}
                                                    defaultValue={searchKeyword}
                                                    /> 
                                                    {/* <button className='' style={{border:"1px", position:"absolute", top: "20px", right: "15px"}} type='button' onClick={(e)=>{setSearchKeyword(""); getList();}}>X</button> */}
                                                </div>
                                                
                                                <div className='col-md-3'>
                                                    Registration Date <br/>
                                                    <DatePicker 
                                                        className=''
                                                        key = "registrationDate"
                                                        id = "registrationDate"
                                                        placeholder="Enter start time"
                                                        dateFormat="d-MM-yyyy"
                                                        selected={startDate}
                                                        onChange={onChange}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        selectsRange
                                                        autoComplete = "off"
                                                    />
                                                    
                                                        
                                                </div>
                                                <div className='col-md-2'><br/>
                                                    <button className='btn btn-sm btn-danger'>Apply</button>
                                                    &nbsp; <button className='btn btn-sm'  type='button' onClick={(e)=>clearFilter(e)}>Reset</button>
                                                </div>
                                                <div className='col-md-4 text-end'><br/>
                                                    <button className='btn btn-sm btn-info'
                                                        onClick={()=>downloadUserData()}
                                                    ><i className='mdi mdi-download'></i> Download</button>
                                                </div>
                                            </form>

                                            <div className='row'>
                                                <div className='col-md-12' style={{marginTop:"20px"}}>
                                                    <Pagination pageInfo={pageInfo} changePage={changePage} /> 
                                            <table className="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr className="bg-primary" style={{color:"white"}}>
                                                        <th scope="col">#</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Email </th>
                                                        <th scope="col" style={{textAlign:"left"}}>Phone</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Created On</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                {lists ? (<>
                                                    {lists?.length > 0 ? (<>
                                                        {lists?.map((row, i) => {
                                                            return(<>
                                                            <tr>
                                                                <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++i}</td>
                                                                <td style={{textAlign:"left"}}>
                                                                    {row?.email}
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    {row?.phone}
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    {DateFunc.format(row?.createdOn*1000)}
                                                                </td>
                                                            </tr>
                                                            </>)
                                                        })}
                                                    </>) : (<>
                                                        <tr>
                                                            <td colSpan={5}>No records found</td>
                                                        </tr>
                                                    </>)}
                                                </>) : (<>
                                                
                                                </>)}
                                                    
                                                </tbody>
                                            </table>
                                            <Pagination pageInfo={pageInfo} changePage={changePage} />
                                                </div>
                                            </div>                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Lead
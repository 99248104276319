import React from "react"

function Pagination(props) {
    const changePage = (p) =>{
        props.changePage(p)
    }
    let pagination = props.pageInfo;
    return (
        <>
        {/* {pagination?.totalPages > 1 ? (<>
            <nav aria-label="Page navigation example">

                <div style={{paddingTop:"10px",position:"absolute"}}>Showing {(pagination.current_page-1)*pagination.perPage +1} to {pagination.current_page*pagination.perPage>pagination.total?pagination.total:pagination.current_page*pagination.perPage} of {pagination.total} records
                </div>

                <ul className="pagination justify-content-end" style={{cursor:"pointer"}}>
                    <li className="page-item" style={{cursor:"pointer"}}>
                    <a className={(pagination?.previousPage === null) ?"page-link disabled":("page-link")} aria-label="Next" disabled={(pagination?.previousPage === null)?"disabled":""}  onClick={() => changePage(pagination?.previousPage)}>
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                    </a>
                </li>
                {(() => {
                    let pageHtml = [];
                    for (let i = 1; i <= pagination?.totalPages; i++) {
                        pageHtml.push(<li className={pagination?.current_page === i ? "page-item active" : "page-item"}><a className="page-link" onClick={() => changePage(i)}>{i}</a></li>)
                    }
                    return pageHtml
                })()}
                
                <li className="page-item" style={{cursor:"pointer"}}>
                <a className={(pagination?.nextPage === null) ?"page-link disabled":("page-link")} aria-label="Next" disabled={(pagination?.nextPage === null)?"disabled":""} onClick={() => changePage(pagination?.nextPage)}>
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                    </a>
                </li>
            </ul>
        </nav>
        </>) : (<></>)} */}

        {pagination?.totalPages > 1 ? (<>
            <nav aria-label="Page navigation" style={{padding:"0 0 10px 0"}}>

                <div style={{display:"inline-block"}}>Showing {(pagination.current_page-1)*pagination.perPage +1} to {pagination.current_page*pagination.perPage>pagination.total?pagination.total:pagination.current_page*pagination.perPage} of {pagination.total} 
                </div> &nbsp; &nbsp;
                <div style={{float:"right"}}>
                    <a className={(pagination?.previousPage === null) ?"page-link disabled":("page-link")} style={{display:"inline-block",cursor:"pointer"}} aria-label="Next" disabled={(pagination?.previousPage === null)?"disabled":""}  onClick={() => changePage(pagination?.previousPage)}>
                            <span aria-hidden="true">&laquo; </span>
                            <span className="sr-only1">Prev</span>
                        </a> &nbsp;
                     <select className="pagination justify-content-end" style={{cursor:"pointer", display:"inline-block"}} onChange={(e) => changePage(e.target.value)}>                        
                    {(() => {
                        let pageHtml = [];
                        for (let i = 1; i <= pagination?.totalPages; i++) {
                            pageHtml.push(<option selected={(pagination?.current_page === i)}> &nbsp; &nbsp; {i} &nbsp; &nbsp; </option>)
                        }
                        return pageHtml
                    })()}
                    </select> &nbsp;
                    <a className={(pagination?.nextPage === null) ?"page-link disabled":("page-link")} style={{display:"inline-block",cursor:"pointer"}} aria-label="Next" disabled={(pagination?.nextPage === null)?"disabled":""} onClick={() => changePage(pagination?.nextPage)}>
                        <span className="sr-only1">Next </span>
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </div>
            </nav>
        </>) : (<></>)}
        </>
    )

}
export default Pagination;

import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { post } from '../services/api.services';
const LoginPage = () => {
    const navigate = useNavigate();
    
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [passwordType, setPasswordType] = useState(true);
    const [keepLoggedIn, setKeepLoggedIn] = useState(0);
    const [emailError, setemailError] = useState();
    const [passwordError, setPasswordError] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        if(localStorage.getItem('token')){
            navigate("/dashboard");
        }
    })
    
    const handleData = (e) => {
        let value = e.target.value;
        let name = e.target.name;
    
        switch (name) {
          case 'username':
            setEmail(value)
            
            break;
          case 'password':
            setPassword(value);
          
            break;
        
          default:
            break;
        }
      }

      const onUserLogin = async () => {
        setError();
        if (!email) {
            return setEmail(" *Required")
        }
        if (!password) {
            return setPasswordError(" *Required")
        }
        let data = {
            email : email,
            password : password,
            keepLoggedIn: keepLoggedIn ? 1 : 0
        }
        let res = await post('/auth/login/', data)
        if(res.status === 1){
            localStorage.setItem('token', res.payload.token);
            localStorage.setItem('userDetail', JSON.stringify(res.payload))
            navigate("/dashboard");
        }else{
            setError(res.message)
        }
    }
    
    useEffect(() => {
        document.title = "Sign In | QIM redFM - Admin"
    }, []);
    return (
        <>
            <div className="auth-page-wrapper pt-5">
                {/* <!-- auth page content --> */}
                <div className="auth-page-content">
                    <div className="container">
                        {/* <!-- end row --> */}
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="card mt-4">
                                    <div className="card-body p-4">
                                        <div className="text-center mt-2">
                                            <div className='mb-2'>
                                                <a href="/" className="d-inline-block auth-logo" style={{background:"#090226","border-radius":"50%","width":"80px","height":"80px","padding":"10px"}}>
                                                    <img src="/assets/images/logo-big.png" alt="" style={{ width: "100%", }} />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <p className='errorMessage'>{error}</p>
                                            <div className="mb-3">
                                                <label for="username" className="form-label">Username <span className='errorMessage'> {emailError}</span></label>
                                                <input type="text" className="form-control" id="username" name="username" placeholder="Enter username" value={email} onChange={(e)=>handleData(e)}/>
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label" for="password-input">Password <span className='errorMessage'> {passwordError}</span></label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <input type={passwordType ? 'password':'text'} className="form-control pe-5 password-input" placeholder="Enter password" name="password" id="password-input" value={password} onChange={(e)=>handleData(e)}  />
                                                    <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={() => setPasswordType(!passwordType)}><i className="ri-eye-fill align-middle"></i></button>
                                                </div>
                                            </div>

                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="auth-remember-check" onChange={() => setKeepLoggedIn(!keepLoggedIn)} />
                                                <label className="form-check-label" for="auth-remember-check"> Keep me logged in</label>
                                            </div>

                                            <div className="mt-4">
                                                <button className="btn btn-primary w-100" style={{backgroundColor: "#d23f39", border: "none"}} onClick={()=>onUserLogin()} >Submit</button>

                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- end card body --> */}
                                </div>
                                {/* <!-- end card --> */}



                            </div>
                        </div>
                        {/* <!-- end row --> */}
                    </div>
                    {/* <!-- end container --> */}
                </div>
                {/* <!-- end auth page content --> */}

                {/* <!-- footer --> */}
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0 text-muted">&copy; QIM redFM {new Date().getFullYear()}. All rights reserved. <i className="mdi mdi-heart text-danger"></i>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* <!-- end Footer --> */}
            </div>
        </>);
};

export default LoginPage;

const m = ["Jan", "Feb", "Mar", "April", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const DateFunc = {
    format: (unixTimestamp,format) =>{
    const date = new Date(unixTimestamp);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strDate =  m[date.getMonth()] + ' ' + date.getDate() + ' ' + date.getFullYear();
    var strTime = ' '+hours + ':' + minutes + ' ' + ampm;
    return strDate + strTime;
  },
  last7Days:(frmt)=>{
    var result = [];
    for (var i=0; i<7; i++) {
        var d = new Date();
        d.setDate(d.getDate() - i);
        result.push( formatDate(d,frmt) )
    }
    return(result.reverse());
  },
  listDaysBetween: (startTimestamp, endTimestamp)=>{
    const startDate = new Date(startTimestamp);
    const endDate = new Date(endTimestamp);
    console.log(startDate)
    console.log(endDate)
    // Ensure the end date is inclusive by setting the time to the end of the day
    endDate.setHours(23, 59, 59, 999);
  
    const dateArray = [];
    let currentDate = new Date(startDate);
    let formatedDateArr = []
    while (currentDate <= endDate) {
        // Push a copy of the current date to the array
        let jsdt = new Date(currentDate);
        dateArray.push(jsdt);
        formatedDateArr.push(formatDate(jsdt,'MMMddYYYY'));
        // Increment the date by one day
        currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return formatedDateArr;
  },
  formatDateCustom:(date, frmt)=>{
    var dd = date.getDate();
    var mm = date.getMonth()+1;
    var MMM = m[date.getMonth()];
    var yyyy = date.getFullYear();
    if(dd<10) {dd='0'+dd}
    if(mm<10) {mm='0'+mm}
    if(frmt == 'MMMddYYYY')
      date = MMM+' '+dd+' '+(yyyy);
    else
      date = dd+'-'+mm+'-'+yyyy;
    return date
  }
  
}

function formatDate(date, frmt){
  var dd = date.getDate();
  var mm = date.getMonth()+1;
  var MMM = m[date.getMonth()];
  var yyyy = date.getFullYear();
  if(dd<10) {dd='0'+dd}
  if(mm<10) {mm='0'+mm}
  if(frmt == 'MMMddYYYY')
    date = MMM+' '+dd+' '+(yyyy);
  else
    date = dd+'-'+mm+'-'+yyyy;
  return date
}



import React, { useState, useEffect } from 'react';
//import { defaultPage } from '../Common/Constant';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import * as UserQuestionApi from '../../api/user-question';
import { masterData } from '../../api/master-data';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Pagination from '../Layout/Pagination';
import {DateFunc} from '../../Common/Date';
import { isDisabled } from '@testing-library/user-event/dist/utils';

function UserQuestion() {
    useEffect(() => {
        document.title = "User Question - QIM RedFm"
    },);
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();
    const [pageInfo, setPagination] = useState({});
    const [showOption, setShowOption] = useState(true)
    // const [countries, setCountries] = useState();
    // const [languages, setLanguages] = useState();
    const [controlType, setControlType] = useState()
    const [submitBtnTxt, setSubmitBtnTxt] = useState('Submit')
    const [queId, setQueId] = useState()
    // const [countryId, setCountryId] = useState()
    // const [languageId, setLanguageId] = useState()
    const [controlTypeId, setControlTypeId] = useState(34)
    const [questionType, setQuestionType] = useState('')
    const [title, setTitle] = useState();
    const [editFormTitle, setEditFormTitle] = useState()
    const [question, setQuestion] = useState("")
    const [explanation, setExplanation] = useState("")
    const [addOption, setAddOption] = useState()
    const [mappedOption, setMappedOption] = useState([])
    const [correctOption, setCorrectOption] = useState("")
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getList();
    },[]
    //,[countryId, languageId]
    )

    useEffect(() => {
        //getCountries()
        // getLanguages()
        getControlType()
    },[])

    // useEffect(() => {
    //     getLanguages()
    // },[countryId])

    // const getCountries = async() => {
    //     let res = await masterData({moduleName:'country'})
    //     if(res.status === 1){
    //         setCountries(res.payload)
    //     }
    // }

    // const getLanguages = async() => {
    //     let res = await masterData({moduleName:'language',countryID:countryId})
    //     if(res.status === 1){
    //         setLanguages(res.payload)
    //     }
    // }

    const getControlType = async() => {
        let res = await masterData({typeSlug:'control-type'})
        if(res?.status === 1){
            setControlType(res.payload)
        }
    }

    const getList = async() =>{
        //if(countryId && languageId){
        let data = {
            page: pageInfo.current_page,
            perPage: 50,
            q: searchKeyword,
            // countryID: countryId,
            // languageID: languageId
        }

            let res = await UserQuestionApi.getList(data);
            //let res = await get('/question/list', data)

            if(res.status === 1){
                setLists(res.payload)
                setPagination(res.paginationInfo)
            }else{
                setLists([])
            }
        //}
        
    }

    const handleControlType = (value) => {
        setShowOption(true)
        if (value === '' || value === null || value === '1272' || value === 1272) {
            setShowOption(false)
        }
        setControlTypeId(value)
    }

    // const checkUnique = async () => {
    //     setErrors({...errors, ['title']: null})
    //     if(languageId && title && title !== editFormTitle){
    //         let data = {
    //             languageID: parseInt(languageId),
    //             title: title
    //         }
    //         let res = await Question.CheckUniqueTitle({payload:data})
    //         if(res.status === 0){
    //             setErrors({...errors, ['title']: res.message})
    //         }
    //     }
    // }

    const handleAddOption = (e) => {
        setAddOption(e.target.value)
        // setForm({...form, ['description']:e.target.value})
    }

    const removeMapped = (item) => {
        let mappedList = mappedOption.filter(maped => maped != item);
        setMappedOption(mappedList);
    }
    
    const moveToMappedOption = async () => {
        addOption?.split('\n').map(function (item, i) {
            if (item !== '' || item !== null) {
                if (mappedOption?.indexOf(item) === -1) {
                    setMappedOption((mappedOption) => [...mappedOption, item])
                }
            }

        })
        await setAddOption("")
    }

    const handlesubmit = async (e) => {
        e.preventDefault();
        setErrors();
        let formData = {
            options:mappedOption,
            correctOption,
            // countryID:countryId,
            // languageID:languageId,
            controlTypeID:controlTypeId,
            questionType,
            title,
            explanation,
            question,
            description:null
        };
        //const formErrors = Question.validateFormData(formData)
        // if(Object.keys(formErrors).length > 0){
        //     setErrors(formErrors)
        // }else{
            let res = {};
            if(queId && queId != "" && queId != 0){
                res = await UserQuestionApi.update({ID:queId,payload:formData});
            }else{
                res = await UserQuestionApi.create({payload:formData});
            }
            if(res.status === 1){                
                //setControlTypeId();
                //setTitle("");
                setQuestion("")
                setExplanation("")
                setQuestionType("")
                setAddOption()
                setMappedOption([])
                getList();
                document.getElementById("questionTypeInput").value="";
            }else{
                setErrors({...errors,['submit']:res.message})
            }
        //}
    }
    const handleQueTitle = async (e) => {
        if(!/[0-9a-zA-Z_]/.test(e.key)){
            e.preventDefault();
        }
    }

  

    const editForm = async(row) => {
        //let res = await UserQuestionApi.getDetail({payload:queId})
        // if(res.status === 1){
            let data = row;//res.payload;
            setQueId(data.ID)
            // setCountryId(data.countryID)
            // setLanguageId(data.languageID)
            //setControlTypeId(data.controlTypeID)
            //setTitle(data.title)
            //setEditFormTitle(data.title)
            setQuestionType(data.questionType);
            setQuestion(data.question);
            setExplanation(data.explanation);

            let opsDt = []
            data.options.forEach(ele => {
                opsDt.push(ele?.option)
            });
            setMappedOption(opsDt)
            setCorrectOption(data.correctOption);
      
            //setSubmitBtnTxt('Update')
            
            // let controlTypeID = data?.controlTypeID
            // setShowOption(true)
            // if(controlTypeID === '' || controlTypeID === null || controlTypeID === '1272' || controlTypeID === 1272){
            //     setShowOption(false)
            // }
            //setControlTypeId(controlTypeID);

            // scroll to top
            window.scrollTo(0, 0)
        //}


    }

    const trashRecord = async (id) => {
        let res = await UserQuestionApi.trash({ID:id})
        if(res.status === 1){
            getList()
        }
    }

    const changePage =(p)=>{
        pageInfo.current_page = p;
        getList();
    }

    const resetForm = () => {
        setQueId()
        // setCountryId()
        // setLanguageId()
        setControlTypeId()
        setTitle()
        document.getElementById('title').value=""
        setQuestion("")
        document.getElementById('question').value=""
        setAddOption("")
        setShowOption(false)
        setMappedOption([])
        setSubmitBtnTxt('Submit')
        setErrors({})
    }

    const updateOrSetStatus = async (id,status) => {
        status = status === 0 ? 1 : 0;
        let res = await UserQuestionApi.updateStatus({id:id,status:status});
        if(res && res.status === 1){
            getList();            
        }
    }

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">User's Question - Janta Ka Sawal</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item active">User Question</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row">
                                {/* <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="">
                                            </div>

                                        </div>
                                    </div>
                                </div> */}
                                {/* <!-- end col --> */}
                            </div>
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-striped1 table-hover1 table_client1">
                                                <thead>
                                                    <tr className="bg-grey">
                                                        {/* <th scope="col">Sr. No.</th> */}
                                                        {/* <th scope="col" style={{textAlign:"left"}}>Question no.</th> */}
                                                        <th scope="col" style={{textAlign:"left"}}>Question</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Available Options</th>
                                                        <th scope="col" style={{textAlign:"left",minWidth:"250px"}}>User Details</th>
                                                        <th scope="col" style={{textAlign:"left",minWidth:"150px"}}>Submitted On</th>
                                                        <th scope="col" style={{textAlign:"right",minWidth:"120px"}}>Action</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                {lists ? (<>
                                                    {lists?.length > 0 ? (<>
                                                        {lists?.map((row, index) => {
                                                            return(<>
                                                            <tr>
                                                            {/* <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++index}</td> */}
                                                               
                                                                {/* <td style={{textAlign:"left"}}>
                                                                    <strong><span style={{textAlign:"left"}}>{row?.title}</span></strong>
                                                                </td> */}
                                                                <td style={{textAlign:"left"}} dangerouslySetInnerHTML={{__html: row?.question}}></td>
                                                                                                                               
                                                                {/* show comma separated options */}
                                                                <td style={{textAlign:"left"}} width="30%">
                                                                    {row?.controlTypeSlug !== 'textbox' ? (<>
                                                                        {row?.options?.map((item, i) => {
                                                                            let correct = false;
                                                                            //for(let j=0; j <row.correctOption.length; j++){
                                                                                if(item?.option == row.correctOption){
                                                                                    correct = true;
                                                                                }
                                                                            //}
                                                                            return (
                                                                                <span style={{"color":(correct)?"green":"","fontWeight":(correct)?"bold":""}}>
                                                                                    - {item.option}  
                                                                                    &nbsp; {correct === true?(<i className='mdi mdi-checkbox-marked-outline'></i>):""}
                                                                                <br/>
                                                                                </span>
                                                                            )
                                                                        })}
                                                                    </>) : (<>
                                                                        <span>-</span>
                                                                    </>)}
                                                                    {row.explanation != null && (
                                                                        <>
                                                                            <div style={{fontSize:"11px"}} dangerouslySetInnerHTML={{__html: '<b>Explanation:</b> '+row?.explanation}}></div>
                                                                        </>
                                                                    )

                                                                    }
                                                                </td>

                                                                <td style={{textAlign:"left"}}>
                                                                    <b>Name</b>: {row.fullName}<br/>
                                                                    <b>City</b>: {row.city}<br/>
                                                                    <b className='mdi mdi-email'></b>: {row.email}<br/>
                                                                    <b className='mdi mdi-cellphone'></b>: {row.phone}<br/>
                                                                </td>
                                                                <td>
                                                                    {DateFunc.format(row?.createdOn*1000,"dd mmm yyyy hh:mm ampm")}
                                                                </td>
                                                                
                                                                <td style={{textAlign:"right"}}>
                                                                    {/* <span className="p-2 font-16" title="Edit question">
                                                                        <i className="ri-edit-2-line pointerC" onClick={() => editForm(row?.ID)}></i>
                                                                    </span>
                                                                    <span className="p-2 font-16" title="Delete question">
                                                                        <i className="ri-delete-bin-2-line del pointerC" onClick={() => trashRecord(row?.ID)}></i>
                                                                    </span> */}
                                                                    <div  className="form-check form-switch" style={{textAlign:'right'}} >
                                                                                    <input className="btn-lg form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={row.status === 1 ? "checked" : ""} onChange={()=>updateOrSetStatus(row.ID, row.status)} /> Status
                                                                                 </div> <br/>
                                                                    <div className="dropdown align-items-right" >
                                                                        <div className="dropdown ms-sm-3 ">
                                                                            <button type="button" className="btn btn-sm " id="page-header-user-dropdown1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{'font-size':"13px", background:"#eee"}}>
                                                                            More <i className="mdi mdi-dots-vertical-circle" ></i>
                                                                            </button>
                                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                                <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>{editForm(row);
                                                                                //Util.scrollToTop()
                                                                                }} ><i className="mdi mdi-pencil"></i> Edit</a>
                                                                                <div className="dropdown-divider"></div>
                                                                                <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>trashRecord(row.ID)} ><i className="mdi mdi-delete"></i> Delete</a>   
                                                                                <div className="dropdown-divider"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </>)
                                                        })}
                                                    </>) : (<>
                                                        <tr>
                                                            <td colSpan={6}>No data found</td>
                                                        </tr>
                                                    </>)}
                                                </>) : (<>
                                                
                                                </>)}
                                                    
                                                </tbody>
                                            </table>
                                            <Pagination pageInfo={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default UserQuestion
export const s3bucket = "https://bytesbrick.s3.ap-south-1.amazonaws.com";
export const apiBaseURL = "https://adminapi.quizindiamovement.in/v1";
//export const apiBaseURL = "http://localhost:4027/v1";
export const defaultPage = {
    total: 1,
    totalPages: 1,
    current_page: 1,
    perPage: 100,
    nextPage: null,
    previousPage: null
}
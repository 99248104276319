import React from 'react';
import {Line} from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js'; 
Chart.register(CategoryScale);

function LineChart(props) {
    console.log(props)
    let chartInfo = props.chartInfo;
    let showTitle = true;
    if(props.chartInfo && props.chartInfo === false){
      showTitle = false;
    }
    const state = {
        labels: chartInfo.labels,
        datasets: [
          {
            label: 'Registration Count',
            fill: false,
            lineTension: 0.5,
            backgroundColor: 'red',
            borderColor: '#34a8eb',
            borderWidth: 2,
            data: chartInfo.data
          }
        ]
      }
 return (
      <div>
        <Line
          data={state}
          options={{
            title:{
              display:true,
              text:'Registration Count Per Day',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        />
      </div>
    );
}
export default LineChart;

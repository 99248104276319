import React from 'react'
import { NavLink } from "react-router-dom";

function Sidebar() {
    const pathName = window.location.pathname;
    // const baseURL = window.location.origin; console.log(baseURL);
    // const splittedPathName = pathName.replace(baseURL,'').trim();
    const splittedPathArr = pathName.split('/');
    const newPathArr = splittedPathArr.splice(1);
    const splittedPathName = newPathArr.join('/');//splittedPathArr[splittedPathArr.length-1];
    console.log(splittedPathName);


    let quizExpMenusSlug = ["quiz", "question","quiz/players", "quiz/winner-manager", "quiz/questions", "quiz/result", "quiz/user-response","user-submitted-questions"];
    let crmExpMenusSlug = ["contact", "leads", "subscribers", "users", "dealer", "supplier"];
    let erpExpMenusSlug = ["category", "attribute-set", "attribute", "brand", "tag", "product", "inventory", "orders", "packages"];
    let uamExpMenusSlug = ["internal-content", "internal-service", "dealer", "supplier"];
    let webExpMenusSlug = ["menu", "blog", "page", "blog-category"];
    
    return (
        <div id="layout-wrapper">

            <div className="app-menu navbar-menu">
                {/* <!-- LOGO --> */}
                <div className="navbar-brand-box">
                    {/* <!-- Dark Logo--> */}
                    <NavLink to={'/'} className="logo logo-dark">
                        <span className="logo-sm">
                            <img src="/assets/images/logo-big.png" alt="" style={{height: "60px" }} />
                        </span>
                        <span className="logo-lg">
                            <img src="/assets/images/logo-big.png" alt="" style={{height: "60px" }} />
                        </span>
                    </NavLink>
                    {/* <!-- Light Logo--> */}
                    <NavLink to={'/'} className="logo logo-light">
                        <span className="logo-sm">
                            <img src="/assets/images/logo-big.png" alt="" style={{height: "60px" }} />
                        </span>
                        <span className="logo-lg">
                            <img src="/assets/images/logo-big.png" alt="" style={{height: "60px" }} />
                        </span>
                    </NavLink>
                    <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover ">
                        <i className="ri-record-circle-line"></i>
                    </button>
                </div>
                <aside className='scrool_s'>
                    <div className='scrool'>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink to={'/dashboard'} className="nav-link menu-link">
                                    {/* <i className="mdi mdi-home"></i> */}
                                    <i className="mdi mdi-chart-bar"></i> 
                                     Dashboard
                                </NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink to={'/analytics'} className="nav-link menu-link">
                                    <i className="mdi mdi-chart-bar"></i> Analytics
                                </NavLink>
                            </li> */}
                            <li className ="nav-item">
                                <a className ="nav-link menu-link" href="#quizSidebarControl" data-bs-toggle="collapse" role="button" aria-controls="quizSidebarControl" aria-expanded={(crmExpMenusSlug.includes(splittedPathName)) ? 'true' : 'false'}>
                                    <i className="mdi mdi-comment-question-outline"></i> Quiz
                                </a>
                                <div className={(quizExpMenusSlug.includes(splittedPathName)) ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'} id="quizSidebarControl">
                                    <ul className="nav nav-sm flex-column">
                                        <li className ="nav-item">
                                            <NavLink to={'/quiz'} className ="nav-link">Manage Quiz</NavLink>
                                        </li>
                                        <li className ="nav-item">
                                            <NavLink to={'/quiz/winner-manager'} className ="nav-link">Winners</NavLink>
                                        </li>
                                        <li className ="nav-item">
                                            <NavLink to={'/quiz/players'} className ="nav-link">Players</NavLink>
                                        </li>
                                        <li className ="nav-item">
                                            <NavLink to={'/question'} className ="nav-link">Manage Question</NavLink>
                                        </li>
                                        <li className ="nav-item">
                                            <NavLink to={'/user-submitted-questions'} className ="nav-link">Janta ka Sawal</NavLink>
                                        </li>
                                        {/* <li className ="nav-item">
                                            <NavLink to={'quiz/results'} className ="nav-link">Results</NavLink>
                                        </li>                                         */}
                                    </ul>
                                </div>
                            </li>
                            <li className ="nav-item">
                                <a className ="nav-link menu-link" href="#crmSidebarControl" data-bs-toggle="collapse" role="button" aria-controls="crmSidebarControl" aria-expanded={(crmExpMenusSlug.includes(splittedPathName)) ? 'true' : 'false'}>
                                    <i className="mdi mdi-account-cog"></i> CRM
                                </a>
                                <div className={(crmExpMenusSlug.includes(splittedPathName)) ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'} id="crmSidebarControl">
                                    <ul className="nav nav-sm flex-column">
                                        {/* <li className ="nav-item">
                                            <NavLink to={'/contact'} className ="nav-link">Contact</NavLink>
                                        </li> */}
                                        <li className ="nav-item">
                                            <NavLink to={'/users'} className ="nav-link">Users</NavLink>
                                        </li>
                                        <li className ="nav-item">
                                            <NavLink to={'/leads'} className ="nav-link">Leads</NavLink>
                                        </li>
                                        {/* <li className ="nav-item">
                                            <NavLink to={'/subscribers'} className ="nav-link">Subscribers</NavLink>
                                        </li> */}
                                        
                                       
                                    </ul>
                                </div>
                            </li>
                            {/* <li className ="nav-item">
                                <a className ="nav-link menu-link" href="#erpSidebarControl" data-bs-toggle="collapse" role="button" aria-controls="erpSidebarControl" aria-expanded={(erpExpMenusSlug.includes(splittedPathName)) ? 'true' : 'false'}>
                                    <i className="mdi mdi-chart-timeline"></i> ERP
                                </a>
                                <div className={(erpExpMenusSlug.includes(splittedPathName)) ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'} id="erpSidebarControl">
                                    <ul className ="nav nav-sm flex-column">
                                        <li className ="nav-item">
                                            <NavLink to={'/category/list'} className ="nav-link">Categories</NavLink>
                                            <ul className ="nav nav-sm flex-column">
                                                <li className ="nav-item">
                                                    <NavLink to={'/attribute-set/list'} className ="nav-link"> Attribute set </NavLink>
                                                    <ul className ="nav nav-sm flex-column">
                                                        <li className ="nav-item">
                                                            <NavLink to={'/attribute/list'} className ="nav-link"> Attributes </NavLink>
                                                        </li>
                                                    </ul>
                                                    <NavLink to={'/brand/list'} className ="nav-link"> Brand </NavLink>
                                                    <NavLink to={'/tag/list'} className ="nav-link"> Tag </NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={'/product/list'} className="nav-link menu-link"> Product </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={'/inventory'} className="nav-link menu-link"> Inventory </NavLink>
                                        </li>
                                        <li className ="nav-item">
                                            <NavLink to={'/orders/list'} className ="nav-link">Orders</NavLink>
                                            <ul className ="nav nav-sm flex-column">
                                                <li className ="nav-item">
                                                    <NavLink to={'/orders/invoices'} className ="nav-link"> Invoices </NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={'/packages'} className="nav-link menu-link"> Packages </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li> */}
                            {/* <li className ="nav-item">
                                <a className ="nav-link menu-link" href="#WEBSidebarControl" data-bs-toggle="collapse" role="button" aria-controls="WEBSidebarControl" aria-expanded={(webExpMenusSlug.includes(splittedPathName)) ? 'true' : 'false'}>
                                    <i className="mdi mdi-web"></i> Website Content
                                </a>
                                <div className={(webExpMenusSlug.includes(splittedPathName)) ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'} id="WEBSidebarControl">
                                    <ul className ="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <NavLink to={'/web/menu'} className="nav-link menu-link"> Menu </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={'/web/blog-category'} className="nav-link menu-link"> Blog Category </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={'/web/page'} className="nav-link menu-link"> Pages </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={'/web/blog'} className="nav-link menu-link"> Blog </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li> */}
                            <li className ="nav-item">
                                <a className ="nav-link menu-link" href="#UAMSidebarControl" data-bs-toggle="collapse" role="button" aria-controls="UAMSidebarControl" aria-expanded={(uamExpMenusSlug.includes(splittedPathName)) ? 'true' : 'false'}>
                                    <i className="mdi mdi-security"></i> User Access
                                </a>
                                <div className={(uamExpMenusSlug.includes(splittedPathName)) ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'} id="UAMSidebarControl">
                                    {/* <ul className ="nav nav-sm flex-column">
                                        <li className="nav-item">
                                            <NavLink to={'/internal-content'} className="nav-link menu-link"> Internal Content </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={'/internal-service'} className="nav-link menu-link"> Internal Service </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={'/dealer'} className="nav-link menu-link"> Dealer </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={'/supplier'} className="nav-link menu-link"> Supplier </NavLink>
                                        </li>
                                    </ul> */}
                                </div>
                            </li>
                        </ul>
                        {/* <!-- Sidebar --> */}
                    </div>
                </aside>
                <div className="sidebar-background"></div>
            </div>
        </div>
    )
}

export default Sidebar
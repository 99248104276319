import React from 'react';
import {Bar} from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js'; 
Chart.register(CategoryScale);

function BarChart(props) {
    console.log(props)
    let BarInfo = props.BarInfo;
    let showTitle = true;
    if(props.BarInfo && props.BarInfo === false){
      showTitle = false;
    }
const state = {
  labels: BarInfo.labels,
  datasets: [
    {
      label: 'TOP CITIES',
      backgroundColor: '#3480eb',
      borderColor: '#3480eb',
      borderWidth: 2,
      data: BarInfo.data
    }
  ]
}


    return (
      <div>
        <Bar
          data={state}
          options={{
            title:{
              display:true,
              text:'No. of registration from cities in 7 days',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        />
      </div>
    );
}
export default BarChart;

import axios from "axios";
import { apiBaseURL } from "../Common/Constant";
import download from "downloadjs";

export const post = async function (url, data) {
  let _token = localStorage.getItem("token");
  if(!_token){
    _token = data._token
  }
  const headers = {
    // "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
  };
  let response;
  try {
    url = apiBaseURL + url;
    let requestOptions = {
      method: "POST",
      url: url,
      data: data,
      headers: headers,
    };
    const postRes = await axios(requestOptions);
    response = postRes.data;
    // if(response.status === 0){
    //   if(response.messageCode === 'TOKEN_EXPIRED' || response.messageCode === 'UNAUTHORIZED'){
    //     localStorage.clear();
    //     window.location = '/'
    //     console.log('unauthorized')
    //   }
    // }
    return response;
  } catch (err) {
     return handleError(err);
  }
};

export const get = async function (url, data) {
  let _token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
  };
  try {
    url = apiBaseURL + url;
    let requestOptions = {
      method: "GET",
      url: url,
      params : data,
      headers: headers,
    };
    const getApiRes = await axios(requestOptions);
    
    // if(getApiRes.data.status === 0){
    //   if(getApiRes.data.messageCode === 'TOKEN_EXPIRED' || getApiRes.data.messageCode === 'UNAUTHORIZED'){
    //     localStorage.clear();
    //     window.location = '/'
    //   }
    // }
    return getApiRes.data;
  } catch (err) {
    return handleError(err);
  }
};

export const putAPI = async function (url, data) {
  let _token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
  };
  try {
    url = apiBaseURL + url;
    let requestOptions = {
      method: "PUT",
      url: url,
      data: data,
      headers: headers,
    };
    const putApiRes = await axios(requestOptions);
    // if(putApiRes.data.status === 0){
    //   if(putApiRes.data.messageCode === 'TOKEN_EXPIRED' || putApiRes.data.messageCode === 'UNAUTHORIZED'){
    //     localStorage.clear();
    //     window.location = '/'
    //   }
    // }
    return putApiRes.data;
  } catch (err) {
    return handleError(err);
  }
};

export const deleteAPI = async function (url, data) {
  let _token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + _token,
  };
  try {
    url = apiBaseURL + url;
    let requestOptions = {
      method: "DELETE",
      url: url,
      data: JSON.stringify(data),
      // params : queryParameter,
      headers: headers,
    };
    const deleteApiRes = await axios(requestOptions);
    // if(deleteApiRes.data.status === 0){
    //   if(deleteApiRes.data.messageCode === 'TOKEN_EXPIRED' || deleteApiRes.data.messageCode === 'UNAUTHORIZED'){
    //     localStorage.clear();
    //     window.location = '/'
    //   }
    // }
    return deleteApiRes.data;
  } catch (err) {
    return handleError(err);
  }
};

export const uploadImageAPI = async function (url, fileData) {
  let _token = localStorage.getItem("token");
  var formData = new FormData();
  // console.log('formdata', fileData)
  for (let i = 0; i < fileData.length; i++) {
    formData.append("files", fileData[i]);
  }
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + _token,
  };
  try {
    const postRes = await axios({
      method: "POST",
      url: apiBaseURL + url,
      data: formData,
      headers: headers,
    });
    // checkToken(postRes.data)
    return postRes.data;
  } catch (err) {    
    return handleError(err);
  }
};

export const getDownloadFile = async function (url, fileName=null) {
  let _token = localStorage.getItem("token");
  try {
    fetch(apiBaseURL + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + _token,
      },
    })
    .then(function (resp) {
      return resp.blob();
    })
    .then(function (blob) {
      let flName = Math.floor(new Date().getTime() / 1000);
      if(fileName && fileName !== null){
        flName = fileName
      }
      return download(blob, flName);
    });
  } catch (err) {
    return { data: { error: "Internal Server Error" } };
  }
};

const handleError = (err) =>{
  console.log(err)
  if(err.response && err.response.data){
    if(err.response.data.messageCode == 'UNAUTHORIZED'){
      localStorage.clear();
      window.location = '/';
    }else{
      return err.response.data;
    }
  } else {
    let response = {status: 0, message: err.message ? err.message: "Internet issue. Please check your internet connection!", messageCode: err.code ? err.code : ''};
    if(err.code && err.code === 'ERR_NETWORK')
      response.message = "Internet issue. Please check your internet connection!";
      return response;
  }
}
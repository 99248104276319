import { get, post, putAPI, deleteAPI } from "../services/api.services";
import Swal from "sweetalert2";
const { defaultPage } = require('../Common/Constant');

export const getList = async({page=defaultPage.current_page, perPage=defaultPage.perPage, searchKeyword = "", status,liveStatus=[]}) => {
    let data = {
        page: page,
        perPage: perPage,
        q: searchKeyword,
        status,
        liveStatus
    }
    let res = await get('/quiz/list', data)
    return res;
}

export const getDetails = async(payload) => {
    let res = await get('/quiz/details/',payload);
    return res;
}

export const trash = async({id}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
    }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/quiz/delete/'+id)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
    })
}

export const updateStatus = async({id, status}) => {
    let publish = status == 0?"unpublish":"publish";
    return Swal.fire({
        text: `Are you sure you want ${publish}?`,
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI('/quiz/update-status/'+id+'/'+status)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const create = async(payload) => {
    let res = await post('/quiz/add', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const update = async(payload) =>{
    let publish = 'update';
    if(payload.liveStatus)
    switch(payload.liveStatus){
        case 1: 
            publish = ' start';
            break;
        case 2: 
            publish = ' finish';
            break;
        case 3: 
            publish = ' publish winner';
            break;
    }
    return Swal.fire({
        text: `Are you sure to  ${publish}?`,
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI(`/quiz/update/${payload.ID}`, payload);
            return res;
        }
    });

}

export const updateLiveStatus = async(payload) =>{
    let publish = 'update';
    if(payload.liveStatus)
    switch(payload.liveStatus){
        case 1: 
            publish = ' start';
            break;
        case 2: 
            publish = ' finish';
            break;
        case 3: 
            publish = ' publish winner';
            break;
    }
    return Swal.fire({
        text: `Are you sure you  ${publish}?`,
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI(`/quiz/update-live-status/${payload.ID}`, payload);
            return res;
        }
    });

}



export const generateBumperWinner = async(payload) =>{
   
    return Swal.fire({
        text: `Are you sure to generate bumper prize winner?`,
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI(`/quiz/generate-bumper-prize-winner/${payload.ID}`, payload);
            return res;
        }
    });

}


// export const updateItemPriority = async(data) => {   
//     let res = await post('/quiz/update-item-priority/', data)
//     return res;
// }

export const setQuestions = async({payload}) => {
    let res = await post('/quiz/set-question', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const getQuestionSetList = async(data) => {
    // let data = {
    //     page: page,
    //     perPage: perPage,
    //     q: searchKeyword
    // }
    let res = await get('/quiz/question-list', data)
    return res;
}

export const updateQuizQuestionSession = async({ID, status,quizID}) => {
    let publish = status == 1?"start":"stop";
    return Swal.fire({
        text: `Are you sure you want ${publish}?`,
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI('/quiz/update-question-session-status/'+ID+'/'+status+'/'+quizID)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}
import { post, get } from "../services/api.services";
const { defaultPage } = require('../Common/Constant');

export const userList = async({page=defaultPage.current_page, perPage=defaultPage.perPage, q = "", city="", startDate="", endDate = ""}) => {   
    if(startDate != "" && startDate != null){
        //const date = new Date();
         startDate = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
    } 
    if(endDate != "" && endDate != null){
        //const date = new Date();
         endDate = `${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}`;
    }
    let res = await get('/user/completed/list', {page,perPage,q,city,startDate, endDate})
    return res;
}

export const userCityList = async() => {
    
    let res = await get('/user/city/list', {})
    return res;
}

export const leadList = async({page=defaultPage.current_page, perPage=defaultPage.perPage, q = "",  startDate="", endDate = ""}) => {
    if(startDate != "" && startDate != null){
        //const date = new Date();
         startDate = `${startDate.getDate()}-${startDate.getMonth() + 1}-${startDate.getFullYear()}`;
    }
    if(endDate != "" && endDate != null){
        //const date = new Date();
         endDate = `${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}`;
    } 
    let res = await get('/user/leads',  {page,perPage,q,startDate, endDate})
    return res;
}


import { get, post, putAPI, deleteAPI } from "../services/api.services";
import Swal from "sweetalert2";
export const getList = async(data) => {
    // let data = {
    //     page: page,
    //     perPage: perPage,
    //     q: searchKeyword,
    //     countryID: countryID,
    //     languageID: languageID
    // }
    let res = await get('/question/list', data)
    return res;
}

export const create = async({payload}) => {
    let res = await post('/question/add', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const getDetail = async({payload}) => {
    let res = await get('/question/details/'+payload)
    return res;
}

export const update = async({ID, payload}) => {
    let res = await putAPI('/question/update/'+ID, payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const trash = async({ID}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/question/trash/'+ID)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const updateStatus = async({id, status}) => {
    return Swal.fire({
        text: 'Are you sure you want to update?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI('/question/update-status/'+id+'/'+status)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const validateFormData = (formData) => {
    const{
        countryID, 
        languageID,
        controlTypeID,
        title,
        question,
        
    } = formData;
    const newErrors = {};
    if(!countryID || countryID === 'Select Country') newErrors.countryID = 'Please select country';
    if(!languageID || languageID === 'Select Language') newErrors.languageID = 'Please select language';
    if(!controlTypeID || controlTypeID === 'Select Control Type') newErrors.controlTypeID = 'Please select control type';
    if(!title || title === '') newErrors.title = 'Please enter question no.';
    if(!question || question === '') newErrors.question = 'Please enter question';
    return newErrors
}

export const questionCheckUniqueTitle = async({payload}) => {
    let res = await post('/question/check-unique-title/', payload)
    return res
}